import { Reducer, useCallback, useEffect, useReducer, useRef } from 'react'
import { DataStream } from '../../Redux/storeTypes'
import DateZoomButtons from './DateZoomButtons/DateZoomButtons'
import ZoomButtons from './ZoomButtons/ZoomButtons'
import { debounce } from 'debounce'
import { week, month } from '../../Assets/DateAproximations'
import {
    chartHeight,
    getEchartsStartOrEnd,
    zoom,
    nodesFillBlanks,
    getFirstDayOfEachMonth,
    maxColumnsShown,
} from './DataFunctions/DataFunctions'
import DataFooter from './DataFooter'
import LoadConcToggle from './LoadConcToggle'
import './Data.scss'
import ReactLoading from 'react-loading'
import { genRainGraphConfig } from './RainGraph'
import { genLineGraphConfig } from './LineGraph'
import { genPestGraphConfig } from './PestGraph'
import DataScrollButtons from './DataScrollButtons/DataScrollButtons'
import DataTooltip from './DataTooltip/DataTooltip'
import DataChart from './DataChart'
import MissingDataNotification from './MissingDataNotification/MissingDataNotification'
import { useAppSelect, useAppDispatch } from '../../Redux/Store'
import { setDateZoomStatus } from '../../Redux/NewReducers'
import TimeSince from '../../Services/TimeSince'

export default function Data() {
    const locations = useAppSelect(state => state.locations.locations)
    const locationId = useAppSelect(state => state.ui.locationId)
    const [data, setData] = useReducer<Reducer<any, any>>((state, newState) => (
        { ...state, ...newState }),
        {
            nodes: [],
            monthTimeArray: [],
            arrayOfMissingStartEnd: [],
            dates: [],
            tempStartValue: TimeSince.DaysAgo(30).getTime(),
            tempEndValue: Date.now(),
            nitrogenConcentrationShown: true
        }
    )
    const echarts_react = useRef(null)
    const maxColumns = maxColumnsShown(window.innerWidth)
    const thresholds = useAppSelect(state => state.threshold.thresholds)
    const dispatch = useAppDispatch()

    const isRainSensor = useCallback(() => {
        return (
            locations[locationId].nodes.length &&
            locations[locationId].nodes[0].unit === 'mm'
        )
    }, [locationId, locations])

    const isPestSensor = useCallback(() => {
        return (
            locations[locationId].nodes.length &&
            locations[locationId].nodes[0].unit === 'ug/L'
        )
    }, [locationId, locations])

    const zoomFuncGen = (zoomType: boolean | string) => {
        const instance = echarts_react.current.getEchartsInstance()
        return zoom(
            zoomType,
            getEchartsStartOrEnd('start', instance),
            getEchartsStartOrEnd('end', instance),
            instance,
            isRainSensor(),
            data.monthTimeArray,
            data.dates
        )
    }

    function getOption() {        
        echarts_react.current?.getEchartsInstance().clear()
        if (isRainSensor()) {
            return genRainGraphConfig(data.nodes, data.dates, data.tempStartValue, data.tempEndValue)
        }
        if (isPestSensor()) {
            return genPestGraphConfig(data.nodes, data.dates, thresholds, maxColumns)
        }
        return genLineGraphConfig(data.nodes, data.dates, data.tempStartValue, data.tempEndValue, data.arrayOfMissingStartEnd)
    }

    const eventHandler = {
        dataZoom: debounce(() => {
            if (echarts_react) {
                const getOption = echarts_react
                    .current
                    .getEchartsInstance()
                    .getOption()
                const startValue = getOption.dataZoom[0].startValue
                const endValue = getOption.dataZoom[0].endValue

                if (isRainSensor()) {
                    dispatch(setDateZoomStatus({
                        monthBackEnabled: startValue - 30 >= 0,
                        weekBackEnabled: startValue - 7 >= 0,
                        weekInEnabled: endValue + 7 < data.dates.length,
                        monthInEnabled: endValue + 30 < data.dates.length
                    }))
                } else if (isPestSensor()) {
                    dispatch(setDateZoomStatus({
                        monthBackEnabled: false,
                        weekBackEnabled: startValue > 0,
                        weekInEnabled: endValue < data.dates.length - 1,
                        monthInEnabled: false
                    }))
                } else {
                    dispatch(setDateZoomStatus({
                        monthBackEnabled: startValue - month >= new Date(data.dates[0]).getTime(),
                        weekBackEnabled: startValue - week >= new Date(data.dates[0]).getTime(),
                        weekInEnabled: endValue + week <
                            new Date(
                                data.dates[data.dates.length - 1]
                            ).getTime(),
                        monthInEnabled: endValue + month <
                            new Date(
                                data.dates[data.dates.length - 1]
                            ).getTime()
                    }))
                }
            }
        }, 300),
    }

    const toggleNitrogenConcs = () => {
        const instance = echarts_react.current.getEchartsInstance()
        setData({
            tempStartValue: getEchartsStartOrEnd('start', instance),
            tempEndValue: getEchartsStartOrEnd('end', instance),
            nitrogenConcentrationShown: !data.nitrogenConcentrationShown
        })
    }

    useEffect(() => {
        let arrayOfNodes: DataStream[] =
            JSON.parse(
                JSON.stringify(
                    locations[locationId].nodes
                )
            )
        arrayOfNodes.sort((a, b) =>
            a.name.localeCompare(b.name)
        )

        arrayOfNodes.forEach(node => {
            if (node.data === null) {
                node.data = []
            }
        })        
        /* Filter disabled streams */
        arrayOfNodes = arrayOfNodes.filter(node => node.display !== false);

        if (arrayOfNodes.length > 1) {
            const filterTerm = !data.nitrogenConcentrationShown &&
                arrayOfNodes.map((node) => node.name).includes('Nitrate-N loads')
                ? 'Nitrate-N concentrations'
                : 'Nitrate-N loads'
            arrayOfNodes = arrayOfNodes.filter((node) => node.name !== filterTerm)            
        }

        const tempArrayOfMissingStartEnd: Array<[string, string, number]> = []

        for (let nodeIndex = 0; nodeIndex < arrayOfNodes.length; nodeIndex++) {
            if (!(isRainSensor() || isPestSensor()) && !(arrayOfNodes[nodeIndex].data.length < 4)) {
                nodesFillBlanks(
                    nodeIndex,
                    arrayOfNodes,
                    tempArrayOfMissingStartEnd
                )
            }
        }
        let tempDates = [
            ...new Set(arrayOfNodes.map((n) => n.data.map((d) => d[0])).flat()),
        ].sort()

        if (isRainSensor()) {
            /* RAIN: IF LAST DATE IS IN THE FUTURE, REMOVE IT */
            if (new Date(tempDates[tempDates.length - 1]) > new Date()) {
                tempDates = tempDates.slice(0, tempDates.length - 1)
            }
        }

        if (echarts_react.current) {
            const echartsOption = echarts_react.current.getEchartsInstance().getOption()
            if (echartsOption.dataZoom[0] !== undefined) {
                setData({
                    nodes: arrayOfNodes,
                    arrayOfMissingStartEnd: tempArrayOfMissingStartEnd,
                    monthTimeArray: getFirstDayOfEachMonth(tempDates),
                    dates: tempDates
                })
            }
        } else {
            setData({
                nodes: arrayOfNodes,
                arrayOfMissingStartEnd: tempArrayOfMissingStartEnd,
                monthTimeArray: getFirstDayOfEachMonth(tempDates),
                dates: tempDates
            })
        }
    }, [isPestSensor, isRainSensor, locationId, locations, data.nitrogenConcentrationShown])    
    if (locations[locationId].nodes.length > 0 && (!locations[locationId].loaded || locations[locationId].nodes.some(node => node.data === null))) {
        return (
            <div style={{ paddingTop: '30vh' }}>
                <div className="columns is-centered is-mobile loading">
                    <div className="column is-2-desktop is-3-tablet is-4-mobile">
                        <ReactLoading
                            type={'bars'}
                            color={'0C66A1'}
                            height={'30vh'}
                            width={''}
                        />
                    </div>
                </div>
            </div>
        )
    } else if (        
        locations[locationId].nodes.length === 0 ||
        data.nodes.length === 0 || 
        locations[locationId].nodes.every(
            node => node.data?.length === 0 || node.display === false
        )
    ) {
        return <h1 className="title noDataTitle">No data</h1>
    }
    return (
        <div id="data">
            <div className="data-card pane data-pane dataPanel">
                <div id={'stickyDiv'}>
                    {!isRainSensor() && !isPestSensor() && (
                        <ZoomButtons
                            zoomIn={() => zoomFuncGen(true)}
                            zoomOut={() => zoomFuncGen(false)}
                            zoomMax={() => zoomFuncGen('max')}
                        />
                    )}
                    {!isPestSensor() && (<DateZoomButtons
                        monthBackFunc={() => zoomFuncGen('monthback')}
                        weekBackFunc={() => zoomFuncGen('weekback')}
                        weekInFunc={() => zoomFuncGen('weekin')}
                        monthInFunc={() => zoomFuncGen('monthin')}
                    />)}
                    {isPestSensor() && (<DataScrollButtons
                        goBackwards={() => zoomFuncGen('backwards')}
                        goForwards={() => zoomFuncGen('forwards')}
                    />)}
                    {locations[locationId].nodes
                        .map(e => e.name)
                        .indexOf('Nitrate-N loads') !== -1 && (                                                        
                            <LoadConcToggle
                                nitrogenConcentrationShown={
                                    data.nitrogenConcentrationShown
                                }
                                toggleNitrogenConcs={toggleNitrogenConcs}
                            />
                        )}
                </div>                                
                <DataChart
                    eventHandler={eventHandler}
                    chartHeight={chartHeight(
                        locations[locationId]?.nodes.length || data.nodes.length,
                        isPestSensor()
                    )}
                    isPestSensor={isPestSensor()}
                    option={getOption()}
                    echarts_react={echarts_react}
                />
                <DataTooltip currentNodes={data.nodes} />
                <MissingDataNotification currentNodes={data.nodes} />
                <DataFooter nodes={data.nodes} />
            </div>
        </div>
    )
}