import { DataLocation, DataStream } from '../../../Redux/storeTypes'
import { day } from '../../../Assets/DateAproximations'
import { latestRainStat, latestRainTitle } from '../../Data/DataFunctions/DataFunctions'
import './MapPopup.scss'
import { useAppSelect } from '../../../Redux/Store'

function showLatestTime(time: string) {
    if (time !== null) {
        const inputDate = new Date(time)
        inputDate.setHours(inputDate.getHours() - 10)
        const currentDate = new Date()
        if (
            inputDate <= currentDate ||
            currentDate.getTime() - inputDate.getTime() < day * 3
        ) {
            if (currentDate.getTime() - inputDate.getTime() < day) {
                return '\n today'
            } else {
                return `${inputDate.getDate()}-${inputDate.getMonth() + 1
                    }-${inputDate.getFullYear()}`
            }
        } else {
            return 'Date error'
        }
    } else {
        return 'No latest data'
    }
}

function showLatestValue(n: DataStream, locationList: DataLocation[], locationId: number) {
    if (typeof n.latestValue === 'string' || n.data?.length === 0 || n.latestValue === undefined || n.latestValue === null) {
        return 'No data'
    }
    if (n.apiIdentifier === 'VRG') {
        return n.data[n.data.length - 1][1].toFixed(1)
    } else if (
        n.unit === 'mm'
    ) {
        return latestRainStat(locationList, locationId)
    }
    return n.latestValue.toFixed(2)
}

function displayLatestTime(n: DataStream) {
    if (n.name === 'Rainfall') {
        return latestRainTitle()
    } else if (n.apiIdentifier === 'VRG') {
        if (n.data.length > 0) {
            return latestRainTitle()
        }
        return 'Click on the sensor to load latest data'
    }
    return showLatestTime(n.latestTime)
}


export default function MapPopup(props: {
    location: DataLocation
    id: number
    popupClicked(locationId: number): Promise<void>
    checkName(nodes: DataStream[], name: string): boolean
}) {
    const locationList = useAppSelect(state => state.locations.locations)
    return (
        <div
            className="content is-medium is-clickable"
            onClick={async () =>
                await props.popupClicked(props.id)
            }
        >
            <p className="mapPopup">{props.location.name}</p>
            {props.location.nodes.map(
                (node, index) =>
                    (!props.checkName(
                        props.location.nodes,
                        'Nitrate-N loads'
                    ) ||
                        node.name !== 'Nitrate-N concentrations') &&
                    (!props.checkName(
                        props.location.nodes,
                        'Nitrate-N concentrations'
                    ) ||
                        node.name !== 'Water level') && (
                        <span key={index}>
                            {props.location.loaded &&
                                node.latestValue !== undefined ? (
                                <span>
                                    <span
                                        style={{
                                            color: '#8e2d55',
                                            fontWeight: 800,
                                        }}
                                    >
                                        {showLatestValue(node, locationList, props.id)}{' '}
                                    </span>
                                    <span style={{ fontSize: '0.7em' }}>
                                        {(node.unit !== 'mm' && node.latestValue) ? node.unit : null}
                                    </span>
                                    <br />
                                    <span
                                        style={{
                                            fontSize: '0.6em',
                                            color: '#949494',
                                        }}
                                    >
                                        {node.unit === 'mm' ? 'Rainfall' : node.name}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: '0.7em',
                                            color: '#8e2d55',
                                        }}
                                    >
                                        <p
                                            className="mt-0 mb-0"
                                        >
                                            {displayLatestTime(node)}
                                        </p>
                                    </span>
                                </span>
                            ) : (
                                props.location.nodes.filter(n => n.name !== "GrabSample").length === 0 ||
                                <span>Loading...</span>
                            )}
                        </span>
                    )
            )}
        </div>
    )
}
