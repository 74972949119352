import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearchPlus,
    faSearchMinus,
    faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons'
import './ZoomButtons.scss'

export default function ZoomButtons(props: {
    zoomIn: VoidFunction
    zoomOut: VoidFunction
    zoomMax: VoidFunction
}) {
    return (
        <nav
            className="breadcrumb is-centered zoomButton mb-0"
        >
            <ul>
                <li key={0}>
                    <button
                        className="button is-white"
                        onClick={props.zoomIn}
                    >
                        <FontAwesomeIcon icon={faSearchPlus} />
                        &nbsp; {'Zoom in'}
                    </button>
                </li>
                <li key={1}>
                    <button
                        className="button is-white"
                        onClick={props.zoomOut}
                    >
                        <FontAwesomeIcon icon={faSearchMinus} />
                        &nbsp; {'Zoom out'}
                    </button>
                </li>
                <li key={2}>
                    <button
                        className="button is-white"
                        onClick={props.zoomMax}
                    >
                        <FontAwesomeIcon icon={faArrowsAltH} />
                        &nbsp; {'Max'}
                    </button>
                </li>
            </ul>
        </nav>
    )
}