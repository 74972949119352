import React, { useState, useRef } from 'react'
import FetchData from '../../../Services/FetchData'
import { debounce } from 'debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import './LocationSearchBar.scss'
import { Map } from 'leaflet'

export default function LocationSearchBar(props: { map: Map }) {
    const [suggestionIndex, setSuggestionIndex] = useState(0)
    const [searchIcon, setSearchIcon] = useState(faSearch)
    const searchRef = useRef(null)
    const [searchSuggestions, setSearchSuggestions] = useState<({
        locationName: string
        locationCoords: [number, number]
    })[]>([])

    const handleKeyboardInput = (event: React.KeyboardEvent) => {
        if (searchSuggestions.length > 0) {
            const termIndex = suggestionIndex
            switch (event.key) {
                case 'Up':
                case 'ArrowUp':
                    if (suggestionIndex > 0) {
                        setSuggestionIndex(termIndex - 1)
                    } else {
                        setSuggestionIndex(searchSuggestions.length - 1)
                    }
                    break
                case 'Down':
                case 'ArrowDown':
                    if (
                        suggestionIndex <
                        searchSuggestions.length - 1
                    ) {
                        setSuggestionIndex(termIndex + 1)
                    } else {
                        setSuggestionIndex(0)
                    }
                    break
                case 'Enter':
                    const suggestion = searchSuggestions[
                        suggestionIndex > -1
                            ? suggestionIndex
                            : 0
                    ]
                    props.map.flyTo(suggestion.locationCoords, 10)
                    searchRef.current.value = suggestion.locationName
                    setSearchSuggestions([])
                    break
            }
        }
    }

    const handleSearchChangeDebounced = debounce(async () => {
        setSuggestionIndex(-1)
        const apiSearchResults = await FetchData.GetLocationsFromSearch(
            searchRef.current.value
        )
        if (searchRef.current.value !== undefined || searchRef.current.value !== '') {
            setSearchIcon(faTimesCircle)
        } else {
            setSearchIcon(faSearch)
        }
        if (apiSearchResults.features.length > 0) {
            const places = apiSearchResults.features.map((location: { place_name: string; geometry: { coordinates: number[] } }) => {
                return {
                    locationName: location.place_name,
                    locationCoords: [
                        location.geometry.coordinates[1],
                        location.geometry.coordinates[0],
                    ],
                }
            })
            setSearchSuggestions(places)
        } else {
            setSearchSuggestions([])
        }
    }, 300)

    return (
        <div className="sizingFix">
            <p
                className="control has-icons-right"
                id="searchBox"
                style={{ marginTop: '10px' }}
            >
                <input
                    id="searchInput"
                    className="input"
                    type="text"
                    placeholder="Search on map..."
                    ref={searchRef}
                    onChange={handleSearchChangeDebounced}
                    onKeyDown={handleKeyboardInput}
                    onBlur={() => {
                        if (
                            suggestionIndex > -1 &&
                            searchSuggestions.length > 0
                        ) {
                            props.map.flyTo(
                                searchSuggestions[
                                    suggestionIndex
                                ].locationCoords,
                                10
                            )
                            searchRef.current.value = searchSuggestions[
                                suggestionIndex
                            ].locationName
                        }
                        setSearchSuggestions([])
                    }}
                />
                <span
                    className="icon is-small is-right is-clickable"
                    onClick={async () => {
                        if (searchRef.current.value.length > 0) {
                            searchRef.current.value = ''
                            setSearchIcon(faSearch)
                        }
                    }}
                >
                    <FontAwesomeIcon icon={searchIcon} />
                </span>
            </p>
            <ul
                className="has-background-white is-unselectable"
                id="searchResults"
            >
                {searchSuggestions.map((suggestion, index) => {
                    return (
                        <li
                            key={index}
                            onClick={() => {
                                props.map.flyTo(
                                    suggestion.locationCoords,
                                    10
                                )
                                searchRef.current.value =
                                    suggestion.locationName
                                setSearchSuggestions([])
                            }}
                            onMouseOver={() => {
                                setSuggestionIndex(index)
                            }}
                            onMouseLeave={() => {
                                setSuggestionIndex(-1)
                            }}
                            className={`search-suggestions is-size-6 ${index === suggestionIndex && `has-background-info has-text-white`}`}
                        >
                            {suggestion.locationName.length > 48 ? suggestion.locationName.slice(0, 48) + '... ' : suggestion.locationName}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

