import { DataLocation } from "../Redux/storeTypes"

export default function DiscoveryLocation(location: any, setActiveLocation: () => void, isActiveArray: any[], currentLocations: Array<DataLocation>, locationIndex: number, setActiveStream: (streamIndex: number) => void) {
    return (
        <div key={location.identifier}>
            <label className="checkbox">
                <input type="checkbox" onClick={() => setActiveLocation()} />
                <span className="pl-1">
                    {`${location.name} (${location.child_streams.length} locations)`}
                    {isLocationInState(location.identifier, currentLocations) && <b> Location already in app</b>}
                </span>
            </label>
            <div className={`${isActiveArray[locationIndex]?.[0] && `box`}`}>
                {isActiveArray[locationIndex]?.[0] && location.child_streams.map((stream: any, streamIndex: number) => (
                    <div key={stream.identifier}>
                        <label className="checkbox columns">
                            <span className="column is-narrow">
                                <input type="checkbox"
                                    disabled={isStreamInState(stream.identifier, location.identifier, currentLocations)}
                                    defaultChecked={isActiveArray[locationIndex]?.[1][streamIndex]}
                                    onClick={() => setActiveStream(streamIndex)}
                                />
                            </span>
                            <span className="pl-1 column">
                                {stream.identifier}
                                <br />
                                {`${stream.description}: ${stream.name}`}
                            </span>
                            {isStreamInState(stream.identifier, location.identifier, currentLocations) && (
                                <span className="column">
                                    <b>
                                        This stream is already in the app
                                    </b>
                                </span>
                            )}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    )
}

function isLocationInState(locationIdentifier: string, locations: Array<DataLocation>): boolean {
    return locations.map(location => location.locationIdentifier).some((locationId) => locationId === locationIdentifier)
}

function isStreamInState(streamIdentifer: string, locationIdentifier: string, locations: Array<DataLocation>): boolean {
    return locations.flatMap(location => location.nodes).some((streamId) => streamId.nodeIdentifier === streamIdentifer)
}