import { useState } from 'react'
import DataAvailabilityIndicator from '../../../Services/DataAvailabilityIndicator'
import './Legend.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { isUUID } from '../../../Services/UuidGenerator'
import { useAppDispatch, useAppSelect } from '../../../Redux/Store'
import {
    setClusterHighlight,
    setNitrateConcentrationsHighlight,
    setNitrateLoadsHighlight,
    setPesticideHighlight,
    setRainDataHighlight,
    setRecentDataUnavailableHighlight
} from '../../../Redux/NewReducers'

export default function Legend(props: { showPesticides: boolean }) {
    const dispatch = useAppDispatch()
    const [hidden, setHidden] = useState(localStorage.getItem('legend-hidden') === 'true')
    const visiblePesticides = useAppSelect(state => state.locations.locations).some(dataLocation => isUUID(dataLocation.locationIdentifier))
    const legendDataTypes = [
        {
            name: 'Rainfall',
            iconId: 'rain-avail-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setRainDataHighlight(e))
            },
        },
        /* Temporarily disabling VRG button */
        /*
        {
            name: 'Virtual rain gauge',
            iconId: 'VRG-avail-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setRainDataHighlight(e))
            },
        },
        */
        props.showPesticides ? {
            name: 'Pesticides',
            iconId: 'pesticide-avail',
            mouseFunction: (e: boolean) => {
                dispatch(setPesticideHighlight(e))
            },
        } : { name: '' },
        {
            name: 'Nitrate-N conc',
            iconId: 'conc-avail-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setNitrateConcentrationsHighlight(e))
            },
        },
        {
            name: 'Nitrate-N loads',
            iconId: 'loads-avail-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setNitrateLoadsHighlight(e))
            },
        },
        {
            name: 'No recent data',
            iconId: 'general-missing-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setRecentDataUnavailableHighlight(e))
            },
        },
        {
            name: 'More sensors',
            iconId: 'more-raw',
            mouseFunction: (e: boolean) => {
                dispatch(setClusterHighlight(e))
            },
        },
    ].filter(l => l.name !== '')
    return (
        <section className="box" id="legendBox">
            <div
                className="button is-small is-fullwidth"
                onClick={() => {
                    setHidden(!hidden)
                    localStorage.setItem('legend-hidden', !hidden ? 'true' : 'false')
                }}
            >
                <FontAwesomeIcon icon={hidden ? faAngleDoubleUp : faAngleDoubleDown} />
                &nbsp;&nbsp;
                <span>{hidden ? 'Show legend' : 'Hide legend'}</span>
            </div>
            {!hidden && legendDataTypes.filter(legendType => visiblePesticides || legendType.name !== 'Pesticides').map((dict, i) => (
                <div
                    onMouseOver={() => {
                        dict.mouseFunction(true)
                    }}
                    onMouseOut={() => {
                        dict.mouseFunction(false)
                    }}
                    className="legendRow"
                    key={i}
                >
                    <span className="legendCell">
                        {DataAvailabilityIndicator(dict.iconId)}
                    </span>
                    <span className="legendCell">{dict.name}</span>
                </div>
            ))}
        </section>
    )
}