import { useEffect, useState } from 'react'
import FetchData from '../../../Services/FetchData'
import './Eula.scss'

export default function Eula() {
    const [eulaShown, showEula] = useState(!window.localStorage.getItem(
        FetchData.LOCALSTORAGE_EULA_KEY
    ))
    useEffect(() => {
        if (!eulaShown) {
            window.localStorage.setItem(FetchData.LOCALSTORAGE_EULA_KEY, 'true')
        }
    }, [eulaShown])
    return (
        eulaShown && (<div
            className={"modal is-active"}
        >
            <div className="modal-background" />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">EULA Notice</p>
                </header>
                <section className="modal-card-body">
                    <iframe
                        src="/eula.html"
                        width="100%"
                        id="eula-iframe"
                        title="eula-iframe"
                    />
                </section>
                <footer className="modal-card-foot">
                    <button
                        id="eula-accept-button"
                        className="button is-primary"
                        onClick={() => showEula(false)}
                    >
                        Accept
                    </button>
                </footer>
            </div>
        </div>)
    )
}