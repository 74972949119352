import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import FetchData from '../Services/FetchData'
import { DataLocation, Latest, LatLng, Threshold } from './storeTypes'

/**
 * This sets the map to be shown or hidden on touch devices
 * @param isShown If true shows map, if false hides map
 */
function doToggleScroll(isShown: boolean) {
  const mapElement = document.getElementById('map')
  if (mapElement) {
    if (isShown) {
      mapElement.classList.add('short-map')
    } else {
      mapElement.classList.remove('short-map')
    }
  }
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alert: {
      is_error: false,
      text: '',
      shown: false,
    }
  },
  reducers: {
    showAlert: (state, action: PayloadAction<{ is_error: boolean, text: string }>) => {
      state.alert = {
        is_error: action.payload.is_error,
        text:
          action.payload.text.substring(0, 250) +
          (action.payload.text.length >= 250 ? '...' : ''),
        shown: true,
      }
    },
    hideAlert: state => {
      state.alert = {
        ...state.alert,
        shown: false
      }
    }
  }
})
export const { showAlert, hideAlert } = alertSlice.actions

interface qualityCode {
  code: number
  message: string
  should_display: boolean
}

interface locationSliceState {
  locations: Array<DataLocation>,
  locationsLoaded: boolean,
  latest: Array<Latest>,
  email: string,
  staff: boolean,
  locationsInMemory: Array<string>,
  codeMessageMapping: qualityCode[]
}
const initiallocationsSliceState: locationSliceState = {
  locations: [],
  locationsLoaded: false,
  latest: [],
  email: '',
  staff: false,
  locationsInMemory: [],
  codeMessageMapping: []
}
export const locationsSlice = createSlice({
  name: 'locations',
  initialState: initiallocationsSliceState,
  reducers: {
    addNodeData: (state, action: PayloadAction<{
      locationId: number
      nodeId: string
      data: Array<[string, number, number]>
      unit?: string,
      display?: boolean,
    }>) => {
      const searchNodeId = (state.locations[action.payload.locationId].nodes.length < 2) ?
        0 :
        state.locations[action.payload.locationId].nodes.findIndex(
          node => node.nodeIdentifier === action.payload.nodeId
        )
      state.locations[action.payload.locationId].nodes[searchNodeId].data = action.payload.data
      state.locations[action.payload.locationId].nodes[searchNodeId].display = action.payload.display ?? true;      
      
      if (action.payload.data.length > 0) {
        if (!state.locations[action.payload.locationId].nodes[searchNodeId].latestValue || !state.locations[action.payload.locationId].nodes[searchNodeId].latestValue) {
          if (action.payload.data[action.payload.data.length - 1][0] > action.payload.data[0][0]) {
            state.locations[action.payload.locationId].nodes[searchNodeId].latestValue = action.payload.data[action.payload.data.length - 1][1]
            state.locations[action.payload.locationId].nodes[searchNodeId].latestTime = action.payload.data[action.payload.data.length - 1][0]
          } else {
            state.locations[action.payload.locationId].nodes[searchNodeId].latestValue = action.payload.data[0][1]
            state.locations[action.payload.locationId].nodes[searchNodeId].latestTime = action.payload.data[0][0]
          }
          if (state.codeMessageMapping.some(code =>
            code.code === action.payload.data[action.payload.data.length - 1][2] &&
            code.should_display === false
          )) {
            state.locations[action.payload.locationId].nodes[searchNodeId].latestValue = null
            state.locations[action.payload.locationId].nodes[searchNodeId].latestTime = null
          }
        }
        if (state.locations[action.payload.locationId].nodes[searchNodeId].name === 'GrabSample' && action.payload.unit) {
          state.locations[action.payload.locationId].nodes[searchNodeId].name = action.payload.unit
        }
        // If there are more than 10 locations loaded, then remove the oldest location from memory to prevent browser memory overuse
        if (state.locations[action.payload.locationId].nodes[searchNodeId].unit !== 'ug/L') {
          if (state.locationsInMemory.length < 11) {
            state.locationsInMemory = [...state.locationsInMemory, state.locations[action.payload.locationId].locationIdentifier]
          } else {
            const searchLocationIndex = state.locations.findIndex(location => location.locationIdentifier === state.locationsInMemory[0])
            state.locations[searchLocationIndex].nodes.forEach(node => node.data = null)
            state.locations[searchLocationIndex].loaded = false
            state.locationsInMemory = [...state.locationsInMemory.slice(1, state.locationsInMemory.length), state.locations[action.payload.locationId].nodes[searchNodeId].nodeIdentifier]
          }
        }
      }
    },
    setLocationLoaded: (state, action: PayloadAction<number>) => {
      state.locations[action.payload].loaded = true
    },
    setLoadInProgress: (state, action: PayloadAction<{ locationId: number, loadInProgress: boolean }>) => {
      state.locations[
        action.payload.locationId
      ].loadInProgress = action.payload.loadInProgress
    },
    setLocations: (state, action: PayloadAction<DataLocation[]>) => {
      state.locationsLoaded = true
      state.locations = action.payload
    },
    setLatest: (state, action: PayloadAction<any[]>) => {
      for (const location in state.locations) {
        for (const n in state.locations[location].nodes) {
          const latestValue: {
            _id: string,
            currentQuality: number,
            currentTime: string,
            currentValue: number,
            previousValue: number
          } | null = action.payload.find(
            (i: Latest) =>
              i._id ===
              state.locations[location].nodes[n].nodeIdentifier
          )
          if (latestValue) {
            state.locations[location].nodes[n].latestTime =
              latestValue.currentTime
            if (latestValue.currentValue !== undefined) {
              state.locations[location].nodes[n].latestValue =
                latestValue.currentValue
            } else if (latestValue.previousValue !== undefined) {
              state.locations[location].nodes[n].latestValue =
                latestValue.previousValue
            } else {
              state.locations[location].nodes[n].latestValue = null
              state.locations[location].nodes[n].latestTime = null
            }
            if (latestValue.currentQuality && state.codeMessageMapping.some(code =>
              code.code === latestValue.currentQuality &&
              code.should_display === false
            )) {
              state.locations[location].nodes[n].latestValue = null
              state.locations[location].nodes[n].latestTime = null
            }
          }
        }
      }
      state.latest = action.payload
    },
    addVRG: (state, action: PayloadAction<DataLocation>) => {
      state.locations = [
        ...state.locations,
        action.payload
      ]
    },
    addGrab: (state, action: PayloadAction<DataLocation>) => {
      state.locations = [
        ...state.locations,
        action.payload
      ]
    },
    removeVRG: (state, action: PayloadAction<DataLocation>) => {
      state.locations = state.locations.filter(
        (location) =>
          location.lat !== action.payload.lat &&
          location.lng !== action.payload.lng
      )
    },
    removeGrab: (state, action: PayloadAction<DataLocation>) => {
      FetchData.updateStoredGrabs(action.payload, 'remove')
      state.locations = state.locations.filter(
        (location) =>
          location.lat !== action.payload.lat &&
          location.lng !== action.payload.lng
      )
    },
    renameVRG: (state, action: PayloadAction<{ VRG: DataLocation, name: string }>) => {
      state.locations = state.locations.map(
        (location) => {
          if (location.lat === action.payload.VRG.lat &&
            location.lng === action.payload.VRG.lng
          ) {
            location.name = action.payload.name
          }
          return location
        }
      )
      FetchData.updateStoredVRGs(action.payload.VRG, 'edit', state.email, state.locations)
    },
    renameGrab: (state, action: PayloadAction<{ name: string, locationId: number, locationIdentifier: string }>) => {
      FetchData.updateGrabSampleName(action.payload.name, action.payload.locationIdentifier)
      state.locations = [...state.locations.slice(0, action.payload.locationId),
      {
        ...state.locations[action.payload.locationId],
        name: action.payload.name,
      },
      ...state.locations.slice(action.payload.locationId + 1),]
    },
    login: (state, action: PayloadAction<{ email: string, staff: boolean }>) => {
      if (action.payload.email === '') {
        window.history.pushState({}, '', `#`)
      }
      state.email = typeof action.payload.email === 'string' ? action.payload.email : ''
      state.staff = action.payload.staff
    },
    setQualityCodes: (state, action: PayloadAction<Array<any>>) => {
      state.codeMessageMapping = action.payload
    }
  }
})
export const {
  addNodeData,
  setLocationLoaded,
  setLoadInProgress,
  setLocations,
  setLatest,
  addVRG,
  addGrab,
  removeVRG,
  removeGrab,
  renameVRG,
  renameGrab,
  login,
  setQualityCodes
} = locationsSlice.actions

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    dataShown: false,
    settingsShown: false,
    locationId: null,
    locationsShown: false,
    mobileLocationsShown: false,
    feedbackShown: false,
    importerShown: false,
    loginShown: false,
    VRG_popup_is_shown: false,
    grab_popup_is_shown: false,
    helperZoomVRG: false,
    satelliteMap: false,
  },
  reducers: {
    toggleDataShown: (state, action: PayloadAction<{ shouldShow: boolean, urlId: string, locationId: number, pushHistory: boolean }>) => {
      const shouldDataBeVisible = action.payload.shouldShow ?? !state.dataShown
      doToggleScroll(shouldDataBeVisible)
      state.dataShown = shouldDataBeVisible
      state.settingsShown = shouldDataBeVisible ? false : state.settingsShown
      state.locationId = action.payload.locationId
      window.history.pushState({}, '', `#${action.payload.pushHistory ? action.payload.urlId : ''}`)
    },
    toggleLocationsShown: (state, action: PayloadAction<boolean>) => {
      state.locationsShown = action.payload ?? !state.locationsShown
    },
    toggleMobileLocationsShown: (state, action: PayloadAction<boolean>) => {
      state.mobileLocationsShown = action.payload ?? !state.mobileLocationsShown
    },
    toggleSettingsShown: (state, action: PayloadAction<boolean>) => {
      state.settingsShown = action.payload ?? !state.settingsShown
      state.dataShown = state.settingsShown ? false : state.dataShown
      doToggleScroll(state.settingsShown)
    },
    toggleFeedbackShown: (state, action: PayloadAction<boolean>) => {
      state.feedbackShown = action.payload ?? !state.feedbackShown
    },
    toggleImporterShown: (state, action: PayloadAction<boolean>) => {
      state.importerShown = action.payload ?? !state.importerShown
    },
    toggleLoginShown: state => {
      state.loginShown = !state.loginShown
    },
    helperZoomVRGToggle: (state, action: PayloadAction<boolean>) => {
      state.helperZoomVRG = action.payload ?? !state.helperZoomVRG
    },
    togglesatelliteMap: state => {
      state.satelliteMap = !state.satelliteMap
    }
  }
})
export const {
  toggleDataShown,
  toggleLocationsShown,
  toggleMobileLocationsShown,
  toggleSettingsShown,
  toggleFeedbackShown,
  toggleImporterShown,
  toggleLoginShown,
  helperZoomVRGToggle,
  togglesatelliteMap
} = uiSlice.actions

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState: {
    favourites: [{ locationId: 'locationId', isLocal: false }]
  },
  reducers: {
    addFavourite: (state, action: PayloadAction<{ locationId: string, isLocal: boolean }>) => {
      if (!state.favourites
        .map((f) => f.locationId)
        .includes(action.payload.locationId)) {
        state.favourites = [
          ...state.favourites,
          {
            locationId: action.payload.locationId,
            isLocal: action.payload.isLocal
          }
        ]
      }
    },
    removeFavourite: (state, action: PayloadAction<string>) => {
      state.favourites = state.favourites.filter(
        (fav) => fav.locationId !== action.payload
      )
    }
  }
})
export const { addFavourite, removeFavourite } = favouritesSlice.actions

export const loadStateSlice = createSlice({
  name: 'stateLoad',
  initialState: {
    stateIsLoaded: false
  },
  reducers: {
    loadState: state => { state.stateIsLoaded = true }
  }
})
export const { loadState } = loadStateSlice.actions

export const highlightSlice = createSlice({
  name: 'highlight',
  initialState: {
    nitrateLoadsHighlight: false,
    pesticideHighlight: false,
    rainDataHighlight: false,
    nitrateConcentrationsHighlight: false,
    recentDataUnavailableHighlight: false,
    clusterHighlight: false
  },
  reducers: {
    setNitrateLoadsHighlight: (state, action: PayloadAction<boolean>) => {
      state.nitrateLoadsHighlight = action.payload
    },
    setPesticideHighlight: (state, action: PayloadAction<boolean>) => {
      state.pesticideHighlight = action.payload
    },
    setRainDataHighlight: (state, action: PayloadAction<boolean>) => {
      state.rainDataHighlight = action.payload
    },
    setNitrateConcentrationsHighlight: (state, action: PayloadAction<boolean>) => {
      state.nitrateConcentrationsHighlight = action.payload
    },
    setRecentDataUnavailableHighlight: (state, action: PayloadAction<boolean>) => {
      state.recentDataUnavailableHighlight = action.payload
    },
    setClusterHighlight: (state, action: PayloadAction<boolean>) => {
      state.clusterHighlight = action.payload
    }
  }
})
export const {
  setNitrateLoadsHighlight,
  setPesticideHighlight,
  setRainDataHighlight,
  setNitrateConcentrationsHighlight,
  setRecentDataUnavailableHighlight,
  setClusterHighlight
} = highlightSlice.actions

export const dateZoomStatusSlice = createSlice({
  name: 'dateZoomStatus',
  initialState: {
    monthBackEnabled: true,
    weekBackEnabled: true,
    weekInEnabled: false,
    monthInEnabled: false,
  },
  reducers: {
    setDateZoomStatus: (state, action: PayloadAction<{
      monthBackEnabled: boolean,
      weekBackEnabled: boolean,
      weekInEnabled: boolean,
      monthInEnabled: boolean
    }>) => {
      state.monthBackEnabled = action.payload.monthBackEnabled
      state.weekBackEnabled = action.payload.weekBackEnabled
      state.weekInEnabled = action.payload.weekInEnabled
      state.monthInEnabled = action.payload.monthInEnabled

    }
  }
})
export const { setDateZoomStatus } = dateZoomStatusSlice.actions

export const tempVRGSlice = createSlice({
  name: 'tempVRG',
  initialState: {
    tempVRG: null
  },
  reducers: {
    setTempVRG: (state, action: PayloadAction<LatLng>) => {
      state.tempVRG = action.payload
    }
  }
})
export const { setTempVRG } = tempVRGSlice.actions

export const tempGrabSlice = createSlice({
  name: 'tempGrab',
  initialState: {
    tempGrab: null
  },
  reducers: {
    setTempGrab: (state, action: PayloadAction<LatLng>) => {
      state.tempGrab = action.payload
    }
  }
})
export const { setTempGrab } = tempGrabSlice.actions

export const thresholdsSlice = createSlice({
  name: 'threshold',
  initialState: {
    thresholds: []
  },
  reducers: {
    setThresholds: (state, action: PayloadAction<Array<Threshold>>) => {
      state.thresholds = action.payload
    }
  }
})
export const { setThresholds } = thresholdsSlice.actions

export const discoverySlice = createSlice({
  name: 'discovery',
  initialState: {
    discoveryLocations: [],
    discoveryShown: false
  },
  reducers: {
    setDiscoveryLocations: (state, action: PayloadAction<Array<any>>) => {
      state.discoveryLocations = action.payload
    },
    setDiscoveryShown: (state, action: PayloadAction<boolean>) => {
      state.discoveryShown = action.payload
    }
  }
})
export const { setDiscoveryLocations, setDiscoveryShown } = discoverySlice.actions

export const catchmentSlice = createSlice({
  name: 'catchment',
  initialState: {
    catchments: [],
  },
  reducers: {
    setCatchments: (state, action: PayloadAction<Array<any>>) => {
      state.catchments = action.payload
    },
  }
})
export const { setCatchments } = catchmentSlice.actions
