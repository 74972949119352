import { Fragment, useEffect, useState } from 'react'
import { useAppSelect } from '../../../Redux/Store'
import { DataStream } from '../../../Redux/storeTypes'
import './DataTooltip.scss'

export default function DataTooltip(props: {
    currentNodes: DataStream[]
}) {
    const [graphValue1, setGraphValue1] = useState(null)
    const [graphValue2, setGraphValue2] = useState(null)
    function updateTooltip(toolTipEvent: CustomEvent<Array<[number | string, number, number]>>) {
        const toolTipExists1 = toolTipEvent.detail.find(trio => trio[1] === 0 && trio[0] !== 'No data')
        setGraphValue1(toolTipExists1 ? toolTipExists1[0] : 'No Data')
        if (props.currentNodes.length > 1) {
            const toolTipExists2 = toolTipEvent.detail.find(trio => trio[1] === 1 && trio[0] !== 'No data')
            setGraphValue2(toolTipExists2 ? toolTipExists2[0] : 'No Data')
        }

    }
    useEffect(() => {
        window.addEventListener("toolTip", (toolTipEvent: any) => updateTooltip(toolTipEvent))
        return () => {
            window.removeEventListener("toolTip", (toolTipEvent: any) => updateTooltip(toolTipEvent))
        }
    })
    const containsLoads = useAppSelect(state => state.locations.locations[state.ui.locationId]).nodes.some(node => node.name.includes('loads'))
    return (
        <Fragment>
            <div className={`${containsLoads ? "loadGraphValueBox1" : "graphValueBox1"} ${(graphValue1 !== null) && `tooltipBox`}`}>
                {graphValue1}
            </div>
            <div className={`${containsLoads ? "loadGraphValueBox2" : "graphValueBox2"} ${(graphValue2 !== null) && `tooltipBox`}`}>
                {graphValue2}
            </div>
        </Fragment>
    )
}