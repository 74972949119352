import { months, rainDropletPath } from "../../Assets/ReuseableStringCons"
import { DataStream } from "../../Redux/storeTypes"
import { addGraphColours } from "./DataFunctions/DataFunctions"

export function genRainGraphConfig(nodes: DataStream[], dates: string[], tempStartValue: number, tempEndValue: number) {
    return {
        tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
                animation: false,
                type: 'shadow',
            },
            show: false,
        },
        title: {
            text: `${nodes[0].name} (${nodes[0].unit})`,
            textAlign: 'left',
            top: '0%',
        },
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        dataZoom: [
            {
                type: 'inside',
                zoomLock: true,
                zoomOnMouseWheel: false,
                moveOnMouseWheel: false,
                startValue: dates[dates.length - 7],
                endValue: dates[dates.length - 1],
                filterMode: 'weakFilter',
            },
        ],
        color: ['#1c7673'],
        grid: {
            left: 50,
            right: 50,
            top: '20%',
            height: '79%',
        },
        singleAxis: {
            connectNulls: true,
            type: 'category',
            gridIndex: 0,
            position: 'top',
            data: dates,
            axisLabel: {
                formatter: (dateNumber: number, index: number) => {
                    const d = new Date(dateNumber)
                    return `${d.getDate()} ${index === 0 || d.getDate() === 1
                        ? '\n' + months[d.getMonth()]
                        : ''
                        }${index === 0
                            ? '\n' + d.getFullYear()
                            : ''
                        }`
                },
            },
            bottom: '0%',
            top: '40%',
            axisTick: {
                show: false,
            },
            splitNumber: 7,
            splitArea: {
                show: true,
                areaStyle: {
                    color: ['rgb(250,250,250)'],
                },
            },
            splitLine: {
                lineStyle: {
                    color: ['rgb(255,255,255)'],
                    type: 'solid',
                },
            },
        },
        series: {
            type: 'scatter',
            coordinateSystem: 'singleAxis',
            name: `${nodes[0].name} (${nodes[0].unit})`,
            data: nodes[0].data,
            connectNulls: false,
            hoverAnimation: false,
            singleAxisIndex: 0,
            showSymbol: false,
            showAllSymbol: false,
            symbol: (dataItem: any) => {
                if (dataItem[1] > 0) {
                    return rainDropletPath
                }
                return 'square'
            },
            symbolSize: 32,
            itemStyle: {
                color: (dataItem: any) => {
                    if (dataItem.data[1] > 0) {
                        return '#1c7673'
                    }
                    return 'rgba(0, 0, 0, 0)'
                },
            },
            symbolKeepAspect: true,
            large: true,
            symbolOffset: [0, -30],
            label: {
                show: true,
                formatter: (value: any) => typeof value.data[1] === 'string' ? value.data[1] : value.data[1] + ' mm',
                position: 'bottom',
                fontSize: 15,
                fontWeight: 'bold',
                color: '#1c7673',
                distance: 20,
            },
            sampling: 'nearest',
            lineStyle: {
                width: 2,
                color: addGraphColours(nodes)[0],
            },
            markArea: {},
        },
    }
}