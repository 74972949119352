import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faAngleDoubleLeft,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'
import './DateZoomButtons.scss'
import { MouseEventHandler } from 'react'
import { useAppSelect } from '../../../Redux/Store'

export default function DateZoomButtons(props: {
    monthBackFunc: MouseEventHandler<HTMLButtonElement>
    weekBackFunc: MouseEventHandler<HTMLButtonElement>
    weekInFunc: MouseEventHandler<HTMLButtonElement>
    monthInFunc: MouseEventHandler<HTMLButtonElement>
}) {
    const monthBackEnabled = useAppSelect(state => state.dateZoomStatus.monthBackEnabled)
    const weekBackEnabled = useAppSelect(state => state.dateZoomStatus.weekBackEnabled)
    const weekInEnabled = useAppSelect(state => state.dateZoomStatus.weekInEnabled)
    const monthInEnabled = useAppSelect(state => state.dateZoomStatus.monthInEnabled)

    return (
        <nav className="breadcrumb has-bullet-separator is-centered dateZoomButton">
            <ul>
                <li>
                    <button
                        onClick={
                            monthBackEnabled ? props.monthBackFunc : undefined
                        }
                        className="button is-white"
                        disabled={!monthBackEnabled}
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                        &nbsp; <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        &nbsp; Month
                    </button>
                </li>
                <li>
                    <button
                        onClick={
                            weekBackEnabled ? props.weekBackFunc : undefined
                        }
                        className="button is-white"
                        disabled={!weekBackEnabled}
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} /> &nbsp; Week
                    </button>
                </li>
                <li>
                    <button
                        onClick={
                            weekInEnabled ? props.weekInFunc : undefined
                        }
                        className="button is-white"
                        disabled={!weekInEnabled}
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                        Week &nbsp;
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </li>
                <li>
                    <button
                        onClick={
                            monthInEnabled ? props.monthInFunc : undefined
                        }
                        className="button is-white"
                        disabled={!monthInEnabled}
                        style={{ paddingLeft: 5, paddingRight: 5 }}
                    >
                        Month &nbsp;
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </button>
                </li>
            </ul>
        </nav>
    )
}
