import { MouseEvent, useState, ReactNode } from 'react'

export default function AsyncButton(props: {
    buttonClassName: string
    onClickAsync: (prop: MouseEvent) => Promise<unknown>
    loadingText: string
    children: ReactNode
}) {
    const [isLoading, setLoadingState] = useState(false)
    return (
        <button
            className={props.buttonClassName}
            disabled={isLoading}
            type={'button'}
            onClick={async (event) => {
                event.stopPropagation()
                setLoadingState(true)
                await props.onClickAsync(event)
                setLoadingState(false)
            }}
        >
            {isLoading ? (
                <div>
                    {props.loadingText}
                </div>
            ) : (
                <div>{props.children}</div>
            )}
        </button>
    )
}