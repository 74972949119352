import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowRight,
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import './DataScrollButtons.scss'
import { useAppSelect } from '../../../Redux/Store'

export default function DataScrollButtons(props: {
    goBackwards: VoidFunction
    goForwards: VoidFunction
}) {
    const weekBack = useAppSelect(state => state.dateZoomStatus.weekBackEnabled)
    const weekIn = useAppSelect(state => state.dateZoomStatus.weekInEnabled)
    return (
        <nav
            className="breadcrumb is-centered zoomButton mb-0"
            aria-label="breadcrumbs"
        >
            <ul>
                <li key={0}>
                    <button
                        className="button is-white"
                        onClick={props.goBackwards}
                        disabled={!weekBack}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                        &nbsp; {'Show earlier'}
                    </button>
                </li>
                <li key={1}>
                    <button
                        className="button is-white"
                        onClick={props.goForwards}
                        disabled={!weekIn}
                    >
                        <FontAwesomeIcon icon={faArrowRight} /> &nbsp; {'Show later'}
                    </button>
                </li>
            </ul>
        </nav>
    )
}
