import { useLayoutEffect, useRef } from 'react'
import './VirtualRainGaugePopupMenu.scss'
import L from 'leaflet'

export default function VirtualRainGaugePopupMenu(props: {
    doneAction: VoidFunction
    title: string
    subtitle: string
    doneActionText: string
    cancelAction: VoidFunction
}) {
    const PopupRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        if (PopupRef?.current !== null) {
            L.DomEvent.disableClickPropagation(PopupRef.current)
        }
    })

    return (
        <div ref={PopupRef} className="card" id="VirtualRainGaugePopupMenu">
            <div className="card-content">
                <p id="popupTitle">{props.title}</p>
                <p id="popupSubTitle">{props.subtitle}</p>
            </div>
            <footer className="card-footer">
                <p className="card-footer-item rainGaugePopupItem">
                    <button
                        className="button rainGaugePopupButton"
                        id="cancelButton"
                        onClick={() => props.cancelAction()}
                    >
                        Cancel
                    </button>
                </p>
                <p className="card-footer-item rainGaugePopupItem">
                    <button
                        className="button rainGaugePopupButton"
                        id="doneButton"
                        onClick={() => props.doneAction()}
                    >
                        {props.doneActionText}
                    </button>
                </p>
            </footer>
        </div>
    )
}
