import { hideAlert } from '../../Redux/NewReducers'
import { useAppDispatch, useAppSelect } from '../../Redux/Store'
import './NotificationPopup.scss'

export default function NotificationPopup() {
    const alert = useAppSelect(state => state.alert.alert)
    const dispatch = useAppDispatch()

    return (
        <div
            className={`notification ${(
                alert.is_error ? 'is-danger' : 'is-primary'
            )} ${(
                alert.shown ? 'isFadingIn' : 'isFadingOut'
            )}`
            }
            id="alert"
        >
            <button
                className="delete"
                onClick={() => dispatch(hideAlert())}
            />
            {alert.text}
        </div>
    )
}
