import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/singleAxis'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import { addScrollBlockers } from './DataFunctions/DataFunctions'
const echarts: any = require('echarts/lib/echarts')

export default function DataChart(props: {
    eventHandler: any
    chartHeight: string
    isPestSensor: boolean
    option: any
    echarts_react: any
}) {
    return (
        <ReactEchartsCore
            echarts={echarts}
            option={props.option}
            notMerge={true}
            style={{
                height: props.chartHeight,
                paddingBottom: '2rem',
                paddingTop: !props.isPestSensor && '3vh',
            }}
            ref={props.echarts_react}
            onEvents={props.eventHandler}
            onChartReady={() => {
                addScrollBlockers()
            }}
        />
    )
}