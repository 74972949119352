import { DataStream, DataLocation } from '../../../Redux/storeTypes'
import { month, day, hour } from '../../../Assets/DateAproximations'

function echartsZoom(
    startDate: number | string,
    endDate: number | string,
    echartsInstance: any
) {
    echartsInstance.dispatchAction({
        type: 'dataZoom',
        startValue: startDate,
        endValue: endDate,
    })
}

function findClosestLowHigh(
    arrayOfNumbers: number[],
    searchNumber: number,
    lowerNumber?: boolean
): number {
    let closestValue = 0
    let nothingMatches = true
    arrayOfNumbers.forEach((number) => {
        if (
            (lowerNumber === undefined ||
                (lowerNumber
                    ? number < searchNumber
                    : number > searchNumber)) &&
            Math.abs(searchNumber - closestValue) >
            Math.abs(searchNumber - number) &&
            number !== searchNumber
        ) {
            closestValue = number
            nothingMatches = false
        }
    })
    if (lowerNumber !== undefined && nothingMatches) {
        return -1
    }
    return closestValue
}

function nodeDataSince(
    nodeList: DataStream[],
    node: number,
    since: Date
): number[] {
    return nodeList[node].data
        .filter((dataNode) => new Date(dataNode[0]) >= since)
        .map((dataNode) => dataNode[1])
}

function numberToString(inputNumber: number): string {
    if (isNaN(inputNumber)) {
        return 'Data error'
    }
    return inputNumber.toFixed(1)
}

export function dataSinceAvg(
    nodeList: DataStream[],
    node: number,
    since: Date
): string {
    const data = nodeDataSince(nodeList, node, since)
    return numberToString(
        data.length > 0 ? data.reduce((x, y) => x + y) / data.length : 0
    )
}

export function dataSinceTotal(
    nodeList: DataStream[],
    node: number,
    since: Date
): string {
    const data = nodeDataSince(nodeList, node, since)
    return numberToString(data.length > 0 ? data.reduce((x, y) => x + y) : 0)
}

export function addGraphColours(nodeList: DataStream[]): string[] {
    return nodeList.map((n: DataStream) => {
        if (n.name === 'Nitrate-N loads') {
            return '#8f2d56'
        } else if (n.name === 'Nitrate-N concentrations') {
            return '#00507a'
        } else if (n.name === 'Rainfall') {
            return '#1c7673'
        } else if (n.name === 'Water level') {
            return '#000'
        } else {
            return '#000'
        }
    })
}

export function chartHeight(nodeListLength: number, isPestSensor: boolean) {
    if (window.innerWidth < 1024 && window.innerWidth > 825) {
        if (isPestSensor) {
            return `${300 * nodeListLength}px`
        }
        return `${nodeListLength > 1 ? 480 : 240}px`
    }
    if (isPestSensor) {
        return `${300 * nodeListLength}px`
    }
    return `${nodeListLength > 1 ? 680 : 340}px`
}

export function getEchartsStartOrEnd(startOrEnd: string, instance: any) {
    if (startOrEnd === 'start') {
        return instance.getOption().dataZoom[0].startValue
    }
    return instance.getOption().dataZoom[0].endValue
}

function isPast9(): boolean {
    // TODO make this check the coordinates and get the proper timezone
    const localTimezoneOffset = new Date().getTimezoneOffset()
    let UTCToAEST = new Date()
    UTCToAEST.setMinutes(UTCToAEST.getMinutes() + localTimezoneOffset)
    UTCToAEST.setHours(UTCToAEST.getHours() + 10)
    return UTCToAEST.getHours() >= 9
}

export function latestRainTitle(): string {
    return isPast9()
        ? 'Rainfall since 9am today'
        : 'Rainfall since 9am yesterday'
}

export function latestRainStat(
    locationList: DataLocation[],
    locationId: number
): string {
    const latestDateValue =
        locationList[locationId].locationIdentifier === 'VRG'
            ? locationList[locationId].nodes[0].data[
            locationList[locationId].nodes[0].data.length - 1
            ][1]
            : locationList[locationId].nodes[0].latestValue
    const latestDateTime =
        locationList[locationId].locationIdentifier === 'VRG'
            ? locationList[locationId].nodes[0].data[
            locationList[locationId].nodes[0].data.length - 1
            ][0]
            : locationList[locationId].nodes[0].latestTime

    /* RAIN: AFTER 9AM: IF LAST DATE IS NOT IN THE FUTURE, SHOW NO STATS */
    /* RAIN: BEFORE 9AM: IF LAST DATE IS NOT TODAY, SHOW NO STATS */
    /* Ie. show stat if after 9am and date is tomorrow OR before 9am and date is today */
    const latestDate = new Date(latestDateTime)
    const currentDate = new Date()
    if (typeof latestDateValue === "string" ||
        ((latestDateValue === undefined || latestDateValue === null) ||
        (currentDate > latestDate && isPast9()) ||
        (currentDate.getDate() > latestDate.getDate() && !isPast9()))
    ) {
        return 'No data'
    }
    return `${latestDateValue.toFixed(1)} mm`
}

export function addScrollBlockers(): void {
    const charts = document.getElementsByClassName('echarts-for-react')
    for (let i = 0; i < charts.length; i++) {
        charts[i].addEventListener(
            'wheel',
            (event) => {
                event.stopPropagation()
            },
            true
        )
        // Just for Firefox
        charts[i].addEventListener(
            'DOMMouseScroll',
            (event) => {
                event.stopPropagation()
            },
            true
        )
        // This gets reference to canvas element
        charts[i].children[0].children[0].addEventListener(
            'touchmove',
            (event: any) => {
                if (event.changedTouches[0].clientY !== (window as any).ly) {
                    event.stopPropagation() // stop prop so that user can scroll
                }
                (window as any).ly = event.changedTouches[0].clientY
            }
        )
    }
}

export function toolTipFormatter(args: any[]): string {
    let tooltip = ``
    args.forEach(({ marker, seriesName, value }) => {
        let displayValue = value[1]
        if (value[1] === null || value[1] === undefined) {
            displayValue = 'No data'
        }
        const d = new Date(value[0])
        if (!tooltip.includes(seriesName) && !seriesName.includes('testName')) {
            tooltip += `<p>${marker} ${seriesName}: ${displayValue} <br>&emsp; on ${d
                .toISOString()
                .substr(0, 19)
                .replace('T', ' ')
                .replace(':00', '')}</p>`
        }
    })
    return tooltip
}

export function zoom(
    zoomIn: string | boolean,
    startValue: number,
    endValue: number,
    instance: unknown,
    isRain: boolean,
    monthTimeArray: number[],
    dateArray: string[]
) {
    const startValueDate = new Date(startValue)
    switch (zoomIn) {
        case 'max':
            echartsZoom(
                new Date(dateArray[0]).getTime(),
                new Date().getTime(),
                instance
            )
            break
        case 'monthin':
            if (isRain) {
                const closestValue = findClosestLowHigh(
                    monthTimeArray,
                    new Date(dateArray[startValue]).getTime(),
                    false
                )
                const monthStart =
                    closestValue > 0
                        ? dateArray.findIndex(
                            (date) =>
                                new Date(date).getTime() === closestValue
                        )
                        : startValue + 30
                echartsZoom(monthStart, monthStart + 6, instance)
            } else {
                const closestValue = findClosestLowHigh(
                    monthTimeArray,
                    startValue,
                    false
                )
                const monthStart = closestValue > 0 ? closestValue : startValue
                const monthEnd = new Date(
                    new Date(closestValue).getFullYear(),
                    new Date(closestValue).getMonth() + 1,
                    0
                ).getTime()
                echartsZoom(monthStart, monthEnd, instance)
            }
            break
        case 'weekin':
            if (isRain) {
                echartsZoom(
                    dateArray.length > startValue + 14
                        ? startValue + 7
                        : dateArray.length - 8,
                    dateArray.length > startValue + 14
                        ? startValue + 13
                        : dateArray.length - 1,
                    instance
                )
            } else {
                const weekStart = new Date(
                    startValueDate.getFullYear(),
                    startValueDate.getMonth(),
                    startValueDate.getDate() + 7
                ).getTime()
                const weekEnd = new Date(
                    startValueDate.getFullYear(),
                    startValueDate.getMonth(),
                    startValueDate.getDate() + 14
                ).getTime()
                echartsZoom(weekStart, weekEnd, instance)
            }
            break
        case 'weekback':
            if (isRain) {
                echartsZoom(
                    startValue - 7 > 0
                        ? dateArray[startValue - 7]
                        : dateArray[0],
                    startValue - 7 > 0
                        ? dateArray[startValue - 1]
                        : dateArray[6],
                    instance
                )
            } else {
                const weekStart = new Date(
                    startValueDate.getFullYear(),
                    startValueDate.getMonth(),
                    startValueDate.getDate() - 7
                ).getTime()
                const weekEnd = new Date(
                    startValueDate.getFullYear(),
                    startValueDate.getMonth(),
                    startValueDate.getDate()
                ).getTime()
                echartsZoom(weekStart, weekEnd, instance)
            }
            break
        case 'monthback':
            if (isRain) {
                const closestValue = findClosestLowHigh(
                    monthTimeArray,
                    new Date(dateArray[startValue]).getTime(),
                    true
                )
                const monthStart =
                    closestValue > 0
                        ? dateArray.findIndex(
                            (date) =>
                                new Date(date).getTime() === closestValue
                        )
                        : startValue - 30
                echartsZoom(monthStart, monthStart + 6, instance)
            } else {
                const closestValue = findClosestLowHigh(
                    monthTimeArray,
                    startValue,
                    true
                )
                const monthStart = closestValue > 0 ? closestValue : startValue
                echartsZoom(monthStart, monthStart + month, instance)
            }
            break
        case 'forwards':
            let closeNumberForwards = endValue
            if ((closeNumberForwards + 3) > (dateArray.length - 1)) {
                closeNumberForwards = dateArray.length - 4
            }
            echartsZoom(dateArray[closeNumberForwards], dateArray[closeNumberForwards + 3], instance)
            break
        case 'backwards':
            let closeNumberBackwards = startValue
            if ((closeNumberBackwards - 3) < 0) {
                closeNumberBackwards = 3
            }
            echartsZoom(dateArray[closeNumberBackwards - 3], dateArray[closeNumberBackwards], instance)
            break
        default:
            const start = startValue + (zoomIn ? day * 5 : -day * 5)
            const end = endValue + (zoomIn ? -day * 5 : day * 5)
            echartsZoom(
                start >= new Date(dateArray[0]).getTime() &&
                    start <= new Date().getTime() &&
                    start < end
                    ? start
                    : startValue,
                end >= new Date(dateArray[0]).getTime() &&
                    end <= new Date().getTime() &&
                    end > start
                    ? end
                    : endValue,
                instance
            )
    }
}

function dataGapGreaterThanGivenHours(dataIndex: number, nodeData: [string, number, number][], hourGap: number): boolean {
    return new Date(nodeData[dataIndex + 1][0]).getTime() -
        new Date(nodeData[dataIndex][0]).getTime() >
        hour * hourGap
}

function fillDatesBetweenGivenPoints(dateStart: Date, dateEnd: Date): [string, number, number][] {
    const dateStartAsNumber = dateStart.getTime()
    const numberOfDataPointsToFill = Math.floor((dateEnd.getTime() - dateStartAsNumber) / hour)
    return Array.from(
        { length: numberOfDataPointsToFill },
        (_, number) =>
            ([new Date(dateStartAsNumber + hour * number).toISOString(), null, -1])
    )
}

/**
 * Takes an array of datastreams
 * 
 * Returns the beginning and end date of null ranges in given datastream
 */
function getStartEndNulls(data: DataStream["data"], nodeIndex: number): [string, string, number][] {
    // Remove single points of data surrounded by nulls since that is difficult to handle and looks bad
    const foo = data.filter(
        (dataPoint, index) =>
            dataPoint[1] === null ||
            index === data.length - 1 ||
            index === 0 ||
            (dataPoint[1] !== null &&
                (data[index - 1][1] !== null ||
                    data[index + 1][1] !== null)
            )
    )
    // Remove nulls so that only the start and end of null ranges are left
    const consequtiveNullsRemoved =
    foo.filter(
            (dataPoint, index) =>
                dataPoint[1] !== null ||
                index === foo.length - 1 ||
                index === 0 ||
                (dataPoint[1] === null &&
                    (foo[index - 1][1] !== null ||
                        foo[index + 1][1])
                )
        )
    // Filter out values that are not null or are single null points
    const startEndNulls = consequtiveNullsRemoved.filter(
        (dataPoint, index) =>
            (index === 0 && (dataPoint[1] === null || consequtiveNullsRemoved[1][1] === null)) ||
            (index === consequtiveNullsRemoved.length - 1 && (dataPoint[1] === null || consequtiveNullsRemoved[index - 1][1] === null)) ||
            (index !== 0 &&
                index !== consequtiveNullsRemoved.length - 1 &&
                dataPoint[1] !== null &&
                (
                    consequtiveNullsRemoved[index - 1][1] === null ||
                    consequtiveNullsRemoved[index + 1][1] === null
                )
            )
    )

    // Add any datapoints surrounded by nulls since they will only be caught once by the filter above
    for (let index = 1; index < consequtiveNullsRemoved.length - 1; index++) {
        if (consequtiveNullsRemoved[index - 1][1] === null && consequtiveNullsRemoved[index + 1][1] === null) {
            startEndNulls.push(consequtiveNullsRemoved[index])
        }
    }

    // Sort the null datapoints by date
    startEndNulls.sort((datapoint1, datapoint2) => datapoint1[0] < datapoint2[0] ? -1 : 1)

    // Get every start of a null range
    const startArray = startEndNulls.filter((_, index) => index % 2 === 0)

    // Get every end of a null range
    const endArray = startEndNulls.filter((_, index) => index % 2 === 1)

    // Map over both the start and end array to create a new array of the form [start, end, nodeIndex]
    return startArray.map((date, index) => [date[0], endArray[index][0], nodeIndex])
}

/**
 * Filles gaps around datapoints with a gap of more than 6 hours with nulls to prevent interpolation on graph
 * @param nodeIndex Current node to insert nulls into
 * @param nodes Array of nodes to insert nulls into
 * @param tempArrayOfMissingStartEnd this array keeps a track of where nulls have been inserted
 */
export function nodesFillBlanks(
    nodeIndex: number,
    nodes: DataStream[],
    tempArrayOfMissingStartEnd: Array<[string, string, number]>,
    dateNow = new Date()
): void {
    const lowestDate = nodes.map(node => node.data).flat().map((val) => val[0]).reduce((acc, val) => {
        return val < acc ? val : acc
    })
    nodes.forEach(node => {
        if (node.data.length < 1) {
            node.data = fillDatesBetweenGivenPoints(new Date(lowestDate), dateNow)
            return
        }
        if (node.data[0][0] > lowestDate) {
            const filledMissingData = fillDatesBetweenGivenPoints(new Date(lowestDate), new Date(node.data[0][0]))
            nodes[nodeIndex].data = filledMissingData.concat(nodes[nodeIndex].data)
        }
    })
    for (
        let dataIndex = 0;
        dataIndex < nodes[nodeIndex].data.length;
        dataIndex++
    ) {
        if (
            dataIndex < nodes[nodeIndex].data.length - 2 &&
            dataGapGreaterThanGivenHours(dataIndex, nodes[nodeIndex].data, 6)
        ) {
            const startingNull = nodes[nodeIndex].data[dataIndex][0]
            const endNull = nodes[nodeIndex].data[dataIndex + 1][0]
            const filledMissingData = fillDatesBetweenGivenPoints(new Date(startingNull), new Date(endNull))
            nodes[nodeIndex].data.splice(dataIndex + 1, 0, ...filledMissingData)
            dataIndex += filledMissingData.length
        }
    }

    // Fill in data between last datapoint and now while filtering out duplicates
    nodes[nodeIndex].data = nodes[nodeIndex].data.concat(
        fillDatesBetweenGivenPoints(
            new Date(
                nodes[nodeIndex].data[nodes[nodeIndex].data.length - 1][0]
            ),
            dateNow
        ).filter(
            element => !nodes[nodeIndex].data.some(
                nodeData => element[0] === nodeData[0]
            )
        )
    ).filter(
        value =>
            value[1] !== null ||
            !nodes[nodeIndex].data.some(
                datapoint =>
                    datapoint[0] === value[0] &&
                    datapoint[1] !== null
            )
    )
    nodes[nodeIndex].data.sort((datapoint1, datapoint2) => (datapoint1[0] > datapoint2[0] ? 1 : -1))

    // filter out null gaps of just one hour
    nodes[nodeIndex].data = nodes[nodeIndex].data.filter(
        (dataPoint, index) =>
            dataPoint[1] !== null ||
            index === 0 ||
            index === nodes[nodeIndex].data.length - 1 ||
            nodes[nodeIndex].data[index - 1][1] === null ||
            nodes[nodeIndex].data[index + 1][1] === null
    )

    const startEndNulls = getStartEndNulls(nodes[nodeIndex].data, nodeIndex)
    startEndNulls.forEach(value => tempArrayOfMissingStartEnd.push(value))
}

export function getFirstDayOfEachMonth(dates: string[]): number[] {
    const firstDaysArray: number[] = []
    dates.forEach((date) => {
        const tempDate = new Date(date)
        if (
            tempDate.getDate() === 1 &&
            firstDaysArray.every((value) => {
                return !(
                    value < tempDate.getTime() + day &&
                    value > tempDate.getTime() - day
                )
            }) &&
            !firstDaysArray.includes(tempDate.getTime())
        ) {
            firstDaysArray.push(tempDate.getTime())
        }
    })
    return firstDaysArray
}

export function maxColumnsShown(windowWidth: number): number {
    if (windowWidth < 350) {
        return 3
    }
    if (windowWidth < 1220) {
        return 4
    }
    if (windowWidth < 1400) {
        return 5
    }
    return 6
}