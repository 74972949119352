import {
    latestRainTitle,
    latestRainStat,
    dataSinceAvg,
    dataSinceTotal,
} from './DataFunctions/DataFunctions'
import { DataStream } from '../../Redux/storeTypes'
import TimeSince from '../../Services/TimeSince'
import { Fragment } from 'react'
import { useAppSelect } from '../../Redux/Store'

export default function DataFooter(props: {
    nodes: DataStream[]
}) {
    const locations = useAppSelect(state => state.locations.locations)
    const currentLocation = useAppSelect(state => state.ui.locationId)
    return (
        <div>
            <nav className={'column content'}>
                <div className="columns">
                    {props.nodes.map((n: DataStream, i: number) => {
                        if (n.unit === 'mm') {
                            return (
                                <div className="column" key={i}>
                                    <p className="heading">
                                        {latestRainTitle()}
                                    </p>
                                    <p className="title">
                                        {latestRainStat(
                                            locations,
                                            currentLocation
                                        )}
                                    </p>
                                </div>
                            )
                        }
                        if (n.unit === 'mgN/L' || n.unit === 'mg/L') {
                            return (
                                <div className="column" key={i}>
                                    <p className="heading">
                                        Average concentrations in last 30
                                        days
                                    </p>
                                    <p className="title">
                                        {dataSinceAvg(
                                            props.nodes,
                                            i,
                                            TimeSince.MonthsAgo(1)
                                        )}{' '}
                                        mgN/L
                                    </p>
                                </div>
                            )
                        }
                        if (n.unit === 'tonnes/day') {
                            return (
                                <div className="column" key={i}>
                                    <p className="heading">
                                        Total loads in last 30 days
                                    </p>
                                    <p className="title">
                                        {dataSinceTotal(
                                            props.nodes,
                                            i,
                                            TimeSince.MonthsAgo(1)
                                        )}{' '}
                                        tonnes
                                    </p>
                                </div>
                            )
                        } else {
                            return <Fragment key={i} />
                        }
                    })}
                    <div className="column extraPad">
                        <p className="heading">Data Source</p>
                        <p className="title">
                            {
                                locations[currentLocation]
                                    .organisation
                            }
                        </p>
                    </div>
                </div>
            </nav>
            {props.nodes[0]?.nodeIdentifier.includes('VRG') && (
                <nav>
                    <div className="column extraPad">
                        <p className="heading">
                            How was this data derived?
                        </p>
                        <p>
                            Rainfall radar values are derived from Bureau of
                            Meteorology 64km rain radar. These values are
                            sampled over a 24 hour period and the daily
                            rainfall is the total accumalation of these
                            samples.
                        </p>
                    </div>
                </nav>
            )}
        </div>
    )
}