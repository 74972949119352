import React, { useState } from 'react'
import { toggleImporterShown } from '../Redux/NewReducers'
import { useAppDispatch, useAppSelect } from '../Redux/Store'
import FetchData from '../Services/FetchData'


export default function Importer() {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [location, setLoaction] = useState('')
    const [compounds] = useState('')
    const [data, setData] = useState<File>(null)
    const [format, setFormat] = useState('')
    const [result, setResult] = useState(null);
    const locations = useAppSelect(state => state.locations.locations)
    const importerShown = useAppSelect(state => state.ui.importerShown)
    const dataFormats = [
        { name: 'Farmacist Lab Report (Excel)', value: 'vnd.ms-excel' }
    ]

    async function formSubmitted(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setResult(null);
        setIsLoading(true)
        const result = await FetchData.SubmitImport(location, compounds, data, format);        
        setIsLoading(false)
        setResult(result)
    }

    function handleClose() {
        if (isLoading === false) {
            setResult(null);
            dispatch(toggleImporterShown(false));
        }
    }

    return (
        <div className={'modal ' + (importerShown && 'is-active')}>
            <div className="modal-background" onClick={handleClose}></div>
            <div className="modal-card is-scrollable">
                <form onSubmit={(e) => formSubmitted(e)}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">Grab Sample Importer</p>
                        <button type="button" className="delete" aria-label="close" onClick={handleClose}></button>
                    </header>
                    <section className="hero is-dark" style={{ backgroundColor: 'rgba(106,106,106,20%)' }}>
                        <div className="hero-body">
                            <div className="container">
                                <h2 className="subtitle">
                                    The importer copies your data into the WQ app.
                                    Visit the <a href={process.env.REACT_APP_BACKEND + '/admin/api/location/'}> <u>admin panel</u> </a> to configure permissions, modify and revoke data, or rename compounds.
                                </h2>
                            </div>
                        </div>
                    </section>

                    <section className="modal-card-body">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Location</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <div className="select">
                                            <select disabled={isLoading} onChange={(e) => setLoaction(e.target.value)} name="location" defaultValue="">
                                                <option value="">[Automatically choose or create location based on coords]</option>
                                                {
                                                    locations.filter(l => l.nodes.filter(n => n.name !== "GrabSample").length === 0).map(l =>
                                                        <option key={l.locationIdentifier} value={l.locationIdentifier}>{l.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Data file</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input disabled={isLoading}
                                            className="input"
                                            type="file"
                                            name="file"
                                            onChange={(e) => {
                                                setResult(null);
                                                setData(e.target.files[0]);
                                            }}
                                            required
                                            accept=".xls,.xlsx,.xlsm,.xlsb,.odf,.ods,.odt">
                                        </input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Data format</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <div className="select">
                                            <select required onChange={(e) => setFormat(e.target.value)} name="format" defaultValue={0}>
                                                {
                                                    dataFormats.map((fileFormat, index) =>
                                                        <option key={index} value={fileFormat.value}>{fileFormat.name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {isLoading &&
                            <section className="notification is-info mb-0">
                                <div className="block">Importing: {data.name} ...</div>
                                <progress className="progress is-small is-primary" max="100"></progress>
                            </section>
                        }
                        {result?.ok &&
                            <section className="notification is-success mb-0">
                                <strong>Succesfully updated locations: </strong>
                                <ul>
                                    {Object.entries(result?.updated_grab_locations)
                                        .map(([loc, count]) => <li key={loc}>{loc}: {count} samples updated.</li>)}
                                </ul>
                            </section>
                        }
                        {
                            result?.ok === false &&
                            <section className="notification is-danger mb-0">
                                <strong>
                                    Error:
                                </strong>
                                <p>{result.message}</p>
                            </section>
                        }
                    <footer className="modal-card-foot">
                        <div className="field is-grouped is-grouped-multiline">
                            <div className="control">
                                <button className="button is-primary" type="submit" disabled={isLoading}
                                >{isLoading ? 'Importing...' : 'Import compounds from data'}</button>
                            </div>
                            <div className="control">
                                <button type="button" className="button" disabled={isLoading} onClick={() => dispatch(toggleImporterShown(false))}>Cancel</button>
                            </div>
                        </div>
                    </footer>
                </form>
            </div>

        </div>
    )
}
