import { day } from "../../Assets/DateAproximations"
import { months } from "../../Assets/ReuseableStringCons"
import { DataStream } from "../../Redux/storeTypes"
import { addGraphColours } from "./DataFunctions/DataFunctions"

export function genLineGraphConfig(nodes: DataStream[], dates: string[], tempStartValue: number, tempEndValue: number, arrayOfMissingStartEnd: Array<[string, string, number]>) {
    const nullDates1 = arrayOfMissingStartEnd.filter(missingValue => missingValue[2] === 0).map(a => [a[0], a[1]]).flat()
    const nullDates2 = arrayOfMissingStartEnd.filter(missingValue => missingValue[2] === 1).map(a => [a[0], a[1]]).flat()
    const missingData1 = {
        name: 'testName',
        unit: 'testUnit',
        data: nodes[0].data.filter(data => data[1] === null).map(data => [data[0], nullDates1.includes(data[0]) ? null : 0, -1]) as [string, number, number][],
        apiIdentifier: 'testAPI',
        latestTime: 'testLatestTime',
        latestValue: 0,
        locationIdentifier: 'testLocationId',
        nodeIdentifier: 'testNodeId',
    }
    const missingData2 = {
        name: 'testName',
        unit: 'testUnit',
        data: nodes[1]?.data.filter(data => data[1] === null).map(data => [data[0], nullDates2.includes(data[0]) ? null : 0, -1]) as [string, number, number][],
        apiIdentifier: 'testAPI',
        latestTime: 'testLatestTime',
        latestValue: 0,
        locationIdentifier: 'testLocationId',
        nodeIdentifier: 'testNodeId',
    }

    nullDates1.forEach(date => {
        const dateIndex = missingData1.data.findIndex(missingData => missingData[0] === date)
        if (dateIndex > -1) {
            missingData1.data[dateIndex][1] = null
        } else {
            missingData1.data.push([date, null, -1])
        }
    })
    nullDates2.forEach(date => {
        const dateIndex = missingData2.data.findIndex(missingData => missingData[0] === date)
        if (dateIndex > -1) {
            missingData2.data[dateIndex][1] = null
        } else {
            missingData2.data.push([date, null, -1])
        }
    })
    missingData1.data.sort((a, b) => {
        if (a[0] < b[0]) {
            return -1
        }
        if (a[0] > b[0]) {
            return 1
        }
        return 0
    })
    missingData2.data?.sort((a, b) => {
        if (a[0] < b[0]) {
            return -1
        }
        if (a[0] > b[0]) {
            return 1
        }
        return 0
    })

    const nodesWithMissingData = nodes.length > 1 ? nodes.concat(missingData1, missingData2) : nodes.concat(missingData1)    
    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                animation: false,
                type: 'line',
            },
            show: true,
            formatter: (args: any[]) => {
                const toolTipEvent = new CustomEvent('toolTip', {
                    detail: args.map(({ value, dataIndex, seriesIndex }: { value: number[], seriesName: string, dataIndex: number, seriesIndex: number }) => {
                        return [
                            (value[1] === null || value[1] === undefined) ? 'No data' : value[1],
                            seriesIndex,
                            nodes[seriesIndex]?.data[dataIndex][2]
                        ]
                    })
                })
                window.dispatchEvent(toolTipEvent)
            },
        },
        title: nodes.map((n: DataStream, i: number) => ({
            text: `${n.name} (${n.unit})`,
            textAlign: 'left',
            top: i === 0 ? 0 : (window.innerWidth < 1024 && window.innerWidth > 825 ? 240 : 340)
        })),
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        dataZoom: [
            {
                type: 'inside',
                zoomLock: false,
                zoomOnMouseWheel: false,
                moveOnMouseWheel: false,
                startValue: tempStartValue,
                endValue: tempEndValue,
                xAxisIndex: nodes.map((_n, i: number) => i),
                filterMode: 'weakFilter',
                minValueSpan: day * 3
            },
        ],
        color: addGraphColours(nodes),
        grid: nodes.map((_n, i: number) => ({
            left: 50,
            right: 50,
            top: i === 0 ? 50 : (window.innerWidth < 1024 && window.innerWidth > 825 ? 270 : 370),
            height: window.innerWidth < 1024 && window.innerWidth > 825 ? 150 : 200
        })),
        xAxis: nodes.map((_n: DataStream, i: number) => {
            return {
                type: 'time',
                gridIndex: i,
                position: 'bottom',
                min: dates[0],
                max: dates[dates.length - 1],
                axisLabel: {
                    formatter: (dateNumber: number, index: number) => {
                        const d = new Date(dateNumber)
                        return `${d.getDate()} ${' ' + months[d.getMonth()]
                            }${index === 1 ? ' ' + d.getFullYear() : ''}`
                    },
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: ['rgb(255,255,255)'],
                    },
                },
                splitLine: {
                    show: false,
                },
            }
        }),
        yAxis: nodes.map((n: DataStream, i: number) => {
            return {
                type: 'value',
                gridIndex: i,
                nameRotate: 90,
                nameLocation: 'middle',
                splitLine: {
                    show: true,
                },
                scale: 'false',
                max: function (value: any) {
                    if ((n.unit === 'mgN/L' || n.unit === 'mg/L') && value.max < 0.2) {
                        return 0.2
                    }
                },
                splitArea: {
                    show: false,
                    areaStyle: {
                        color: ['rgb(250,250,250)'],
                    },
                },
            }
        }),
        series: nodesWithMissingData.map((n: DataStream, i: number) => ({
            type: 'line',
            coordinateSystem: 'cartesian2d',
            name: `${n.name} (${n.unit})`,
            data: n.data.map(value => [value[0], value[1]]),
            connectNulls: false,
            hoverAnimation: false,
            xAxisIndex: i < nodes.length ? i : i - nodes.length,
            yAxisIndex: i < nodes.length ? i : i - nodes.length,
            showSymbol: false,
            showAllSymbol: false,
            symbol: 'none',
            symbolSize: 0,
            symbolKeepAspect: true,
            large: true,
            sampling: i < nodes.length ? 'nearest' : '',
            lineStyle: {
                width: 2,
                color: i < nodes.length ? '#121F50' : '#E40D0D',
            },
            areaStyle: {
                origin: i < nodes.length ? 'auto' : 'end',
                color: i < nodes.length ? '#EAECF6' : '#FAD8D8',
                opacity: i < nodes.length ? 1 : 0.5,
            }
        })),
    }
}
