import { useEffect, useState } from 'react'
import Map from '../Map/Map'
import LocationListPanel from '../Map/LocationListPanel/LocationListPanel'
import Data from '../Data/Data'
import PaneHeader from '../PaneHeader/PaneHeader'
import FetchData from '../../Services/FetchData'
import SettingsPanel from '../SettingsPanel/SettingsPanel'
import Login from './Login/Login'
import ReactGA from 'react-ga4'
import NotificationPopup from '../NotificationPopup/NotificationPopup'
import Eula from './Eula/Eula'
import NavBar from '../Navbar/Navbar'
import Feedback from '../Feedback'
import Importer from '../Importer'
import './App.scss'
import ReactLoading from 'react-loading'
import Holidays from '../../Assets/holidays.svg'
import DiscoveryImporter from '../DiscoveryImporter'
import { useAppDispatch, useAppSelect } from '../../Redux/Store'
import { toggleDataShown, toggleLocationsShown, toggleLoginShown, toggleMobileLocationsShown, togglesatelliteMap, toggleSettingsShown } from '../../Redux/NewReducers'

export default function App() {
    const discoveryShown = useAppSelect(state => state.discovery.discoveryShown)
    const locationsShown = useAppSelect(state => state.ui.locationsShown)
    const mobileLocationsShown = useAppSelect(state => state.ui.mobileLocationsShown)
    const locationsLoaded = useAppSelect(state => state.locations.locationsLoaded)
    const dataShown = useAppSelect(state => state.ui.dataShown)
    const locations = useAppSelect(state => state.locations.locations)
    const locationId = useAppSelect(state => state.ui.locationId)
    const staff = useAppSelect(state => state.locations.staff)
    const email = useAppSelect(state => state.locations.email)
    const settingsShown = useAppSelect(state => state.ui.settingsShown)
    const dispatch = useAppDispatch()
    const [setupHasRun, setSetupState] = useState(false)
    const satellite = useAppSelect(state => state.ui.satelliteMap)
    const [oneTimeOpen, setHasDoneOneTimeOpen] = useState(false)

    useEffect(() => {
        async function initialSetup() {
            setSetupState(true)
            if (window.localStorage.getItem(FetchData.LOCALSTORAGE_SATELLITE_KEY) === 'y' && !satellite) {
                dispatch(togglesatelliteMap())
            }
            const emailResponse = await FetchData.SetEmail()
            FetchData.LoadLocations()
            FetchData.LoadThresholds()
            FetchData.LoadQualityCodes()
            FetchData.LoadFavourites(emailResponse?.email)
            FetchData.addLocalStorageVRGs()
            FetchData.LoadLatest()
            const url = new URL(window.location.href)
            if (url.searchParams.get('token') !== null && url.searchParams.get('email') !== null) {
                dispatch(toggleLoginShown())
            }
        }
        if (!setupHasRun) {
            initialSetup()
            ReactGA.initialize(process.env.REACT_APP_GA_CODE)
            ReactGA.set({ anonymizeIp: true })
            ReactGA.send("pageview")
        }
    }, [setupHasRun, dispatch, locations.length, email, satellite])

    useEffect(() => {
        if (!oneTimeOpen && locations.length > 0) {
            setHasDoneOneTimeOpen(true)
            /* Makeshift router */
            try {
                const urlId = document.location.hash.replace('#', '')
                const locationId = locations.findIndex(location => String(location.id) === decodeURI(urlId))
                if (locationId !== -1) {
                    dispatch(toggleDataShown(
                        {
                            shouldShow: true,
                            locationId: locationId,
                            urlId: urlId,
                            pushHistory: true
                        }
                    ))
                    FetchData.LoadNodes(locationId)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [dispatch, locations, oneTimeOpen])

    return (
        <div className="app">
            <div className="columns panes is-gapless">
                <Eula />
                <Feedback />
                <Importer />
                {discoveryShown && <DiscoveryImporter />}
                {(locationsShown ||
                    mobileLocationsShown) && (
                        <div className="column is-4 is-12-touch fullHeight">
                            <PaneHeader
                                text="All locations"
                                onClose={() => {
                                    dispatch(toggleLocationsShown(false))
                                    dispatch(toggleMobileLocationsShown(false))
                                }}
                                showFav={false}
                            />
                            <LocationListPanel />
                        </div>
                    )}
                <div className="column">
                    <NavBar />
                    <Login />
                    {!locationsLoaded && (
                        <div className="appLoadingOverlay">
                            {
                                new Date().getMonth() === 11 &&
                                (new Date().getDay() === 25 || new Date().getDay() === 31) &&
                                <div>
                                    <div className="center">
                                        <img src={Holidays} alt="Happy festive season!"
                                            style={{
                                                maxHeight: '400px',
                                                maxWidth: '400px'
                                            }} />
                                        <span
                                            style={{
                                                fontSize: '1.7rem'
                                            }}>
                                            Happy festive season!
                                        </span>
                                    </div>
                                </div>
                            }
                            <ReactLoading
                                type={'bars'}
                                color={'0C66A1'}
                                height={'30vh'}
                                width={'30vh'}
                                className={`${locationsShown && `margin-right-35vw`}`}
                            />
                        </div>
                    )}
                    <Map />
                </div>
                {dataShown && (
                    <div
                        className={`column is-5 is-12-touch data-pane`}
                    >
                        <PaneHeader
                            text={
                                locations[locationId].name
                            }
                            subText={
                                locations[locationId].catchments
                            }
                            onClose={() =>
                                dispatch(toggleDataShown({ shouldShow: false, urlId: null, locationId: null, pushHistory: false }))
                            }
                            showFav={
                                locations[locationId].locationIdentifier !== 'VRG'
                            }
                            deleteVRG={
                                locations[locationId].locationIdentifier === 'VRG'
                            }
                            renameVRG={
                                locations[locationId].locationIdentifier === 'VRG'
                            }
                            deleteGrab={
                                staff
                            }
                            renameGrab={
                                staff && locations[locationId].locationIdentifier !== 'VRG'
                            }
                        />
                        <Data />
                    </div>
                )}

                {settingsShown && (
                    <div className="column is-3">
                        <PaneHeader
                            text="Settings"
                            onClose={() => {
                                dispatch(toggleSettingsShown(false))
                            }}
                            showFav={false}
                        />
                        <SettingsPanel />
                    </div>
                )}
            </div>
            <NotificationPopup />
        </div >
    )
}