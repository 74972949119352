import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import Favourite from '../Favourite'
import './PaneHeader.scss'
import { faTrashAlt, faPen } from '@fortawesome/free-solid-svg-icons'
import AsyncButton from '../AsyncButton'
import { useAppDispatch, useAppSelect } from '../../Redux/Store'
import { renameVRG, renameGrab, removeVRG, toggleDataShown } from '../../Redux/NewReducers'

export default function PaneHeader(props: {
    onClose: () => void
    text: string
    subText?: string
    showFav: boolean
    deleteVRG?: boolean,
    renameVRG?: boolean,
    deleteGrab?: boolean,
    renameGrab?: boolean,
}) {
    const currentLocation = useAppSelect(state => state.locations.locations[state.ui.locationId])
    const currentLocationId = useAppSelect(state => state.ui.locationId)
    const [renamingRVG, setRenamingRVG] = useState(false)
    const [VRGName, setVRGName] = useState('')
    const dispatch = useAppDispatch()
    const toggleRenameVRG = (save: boolean) => {
        setRenamingRVG(!renamingRVG)
        if (save && VRGName !== '') {
            if (props.renameVRG) {
                dispatch(renameVRG({
                    VRG: currentLocation,
                    name: VRGName.toString().trim().replace(/ +/g, ' ')
                }
                ))
            } else {
                dispatch(renameGrab({
                    locationId: currentLocationId,
                    locationIdentifier: currentLocation.locationIdentifier,
                    name: VRGName.toString().trim().replace(/ +/g, ' ')
                }
                ))
            }

        }
    }


    return (
        <nav role="navigation">
            <div
                id="dataHeader"
                className="navbar is-light is-fixed-top level is-mobile"
            >
                <button
                    className="level-left button buttonSize"
                    onClick={props.onClose}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="level-left">
                    <div>
                        <p className="has-text-weight-bold is-size-5">
                            {renamingRVG ?
                                <input className="input" type="text" name="name" onChange={(e) => setVRGName(e.target.value)} defaultValue={props.text}
                                    maxLength={35}
                                    minLength={5}
                                />
                                : props.text.substring(0, 25) +
                                (props.text.length >= 25 ? '...' : '')}
                        </p>
                        <p>{renamingRVG ? '' : props.subText}</p>
                    </div>
                </div>
                <div className="level-right">
                    {props.showFav && (
                        <Favourite
                            locationId={
                                currentLocation.id
                            }
                        />
                    )}
                    {props.deleteVRG && !renamingRVG && (
                        <AsyncButton
                            buttonClassName={
                                'button is-light ml-1 is-pulled-right '
                            }
                            loadingText={'...'}
                            onClickAsync={async () => {
                                dispatch(removeVRG(currentLocation))
                                dispatch(toggleDataShown(
                                    {
                                        shouldShow: false,
                                        urlId: null,
                                        locationId: null,
                                        pushHistory: true
                                    }
                                ))
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </AsyncButton>
                    )}
                    {(props.renameVRG || props.renameGrab) && !renamingRVG && (
                        <button className="button is-light ml-1 is-pulled-right"
                            onClick={() => toggleRenameVRG(false)}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </button>
                    )}
                    {renamingRVG && (
                        <button className="button is-light ml-1 is-pulled-right"
                            onClick={() => toggleRenameVRG(true)}
                        >
                            <FontAwesomeIcon icon={faCheck} />
                        </button>
                    )}
                    {renamingRVG && (
                        <button className="button is-light ml-1 is-pulled-right"

                            onClick={() => toggleRenameVRG(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                </div>
            </div>
        </nav>
    )
}
