import ReactGA from 'react-ga4'
import { DataStream } from '../Redux/storeTypes'

export default async function locationClickAnalytics(
    nodes: DataStream[]
) {
    ReactGA.event({
        category: 'Data',
        action: 'Sensor clicked from sensor list',
        label: 'Sensor clicked from sensor list',
    })
    if (
        nodes.some(
            (node) => node.name === 'Nitrate-N loads'
        )
    ) {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Nitrogen loads',
        })
    } else if (
        nodes.some(
            (node) => node.name === 'Nitrate-N concentrations'
        )
    ) {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Nitrogen',
        })
    } else if (
        nodes.some(
            (node) => node.name === 'Rainfall'
        )
    ) {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Rain',
        })
    } else if (nodes.some((node) => node.name === 'GrabSample')) {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Grab Sample',
        })
    } else if (nodes.some((node) => node.locationIdentifier === 'VRG')) {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Virtual rain gauge',
        })
    } else {
        ReactGA.event({
            category: 'Data',
            action: 'Looked at sensors',
            label: 'Unknown sensor',
        })
    }
}