import React, { useState } from 'react'
import FetchData from '../../../Services/FetchData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUser,
    faSignInAlt,
    faKey,
    faLock,
    faUndo,
} from '@fortawesome/free-solid-svg-icons'
import { useAppDispatch, useAppSelect } from '../../../Redux/Store'
import { showAlert, toggleLoginShown } from '../../../Redux/NewReducers'

export enum Modes {
    Login,
    Signup,
    Recovery,
    RecoveryOTP
}

function messageLoading(mode: Modes) {
    switch (mode) {
        case Modes.Login:
            return 'Logging you in...'
        case Modes.Signup:
            return 'Signing you up...'
        case Modes.Recovery:
            return 'Sending recovery email...'
        case Modes.RecoveryOTP:
            return 'Setting new password...'
    }
}

export function message(mode: Modes) {
    switch (mode) {
        case Modes.Login:
            return 'Login'
        case Modes.Signup:
            return 'Signup'
        case Modes.Recovery:
            return 'Send recovery email'
        case Modes.RecoveryOTP:
            return 'Set new password & login'
    }
}

export default function Login() {
    const loginShown = useAppSelect(state => state.ui.loginShown)
    const dispatch = useAppDispatch()

    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    const [email, setEmail] = useState(url.searchParams.get('email') ?? '')
    const [mode, setMode] = useState(token !== null && email !== null
        ? Modes.RecoveryOTP
        : Modes.Login)
    const [otp, changeOTP] = useState(token ?? '')
    const [password, changePassword] = useState('')
    const [repeatPassword, changeRepeatPassword] = useState('')
    const [loading, setLoading] = useState(false)

    function setModeHandler(m: Modes) {
        setMode(m)
        switch (mode) {
            case Modes.Login:
                changePassword('')
                break
            case Modes.Signup:
                changePassword('')
                changeRepeatPassword('')
                break
            case Modes.RecoveryOTP:
                changePassword('')
                changeRepeatPassword('')
                changeOTP('')
                break
        }
    }

    async function recoveryOtp(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        if (password !== repeatPassword) {
            dispatch(
                showAlert(
                    {
                        text: "Repeated password doesn't match, please try again",
                        is_error: true
                    }
                )
            )
            return
        }
        setLoading(true)
        await FetchData.RecoveryOtp(
            email,
            otp,
            password
        )
        setLoading(false)
    }

    async function recovery(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setLoading(true)
        const res = await FetchData.Recovery(email)
        setLoading(false)
        if (res) {
            setMode(Modes.RecoveryOTP)
        }
    }

    async function formSubmitted(event: React.FormEvent<HTMLFormElement>) {
        switch (mode) {
            case Modes.Login:
                await login(event)
                break
            case Modes.Signup:
                await signup(event)
                break
            case Modes.Recovery:
                await recovery(event)
                break
            case Modes.RecoveryOTP:
                await recoveryOtp(event)
                break
        }
    }

    async function login(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setLoading(true)
        await FetchData.Login(email, password)
        setLoading(false)
    }

    async function signup(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        if (password !== repeatPassword) {
            dispatch(
                showAlert(
                    {
                        text: "Repeated password doesn't match, please try again",
                        is_error: true
                    }
                )
            )
            return
        }
        setLoading(true)
        const res = await FetchData.Signup(
            email,
            password
        )
        setLoading(false)
        if (res) {
            setMode(Modes.Login)
        }
    }

    return (
        <div
            id="login-form"
            className={
                `modal is-active ${(loginShown ? `` : `is-hidden`)}`
            }
        >
            <div
                className="modal-background"
                onClick={() => dispatch(toggleLoginShown())}
            />
            <form
                className="modal-card"
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => formSubmitted(e)}
            >
                <header className="modal-card-head">
                    <p className="modal-card-title">{message(mode)}</p>
                    <button
                        className="delete"
                        aria-label="close"
                        onClick={(event) => {
                            event.preventDefault()
                            dispatch(toggleLoginShown())
                        }}
                    />
                </header>
                <section className="modal-card-body">
                    {mode !== Modes.RecoveryOTP && (
                        <div className="field">
                            <p className="control has-icons-left has-icons-right">
                                <input
                                    name="email"
                                    className="input"
                                    type="email"
                                    placeholder="Email"
                                    autoComplete="username email"
                                    value={email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setEmail(e.target.value)
                                    }
                                    required
                                    autoFocus
                                />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            </p>
                        </div>
                    )}

                    {mode === Modes.RecoveryOTP && (
                        <div className="field">
                            <p className="control has-icons-left">
                                <input
                                    name="otp"
                                    className="input"
                                    type="number"
                                    placeholder="Secret code from email"
                                    value={otp}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        changeOTP(e.target.value)
                                    }
                                    required
                                    minLength={6}
                                    maxLength={6}
                                />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={faKey} />
                                </span>
                            </p>
                        </div>
                    )}

                    {mode !== Modes.Recovery && (
                        <div className="field">
                            <p className="control has-icons-left">
                                <input
                                    name="password"
                                    className="input"
                                    type="password"
                                    placeholder="Password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        changePassword(e.target.value)
                                    }
                                    required
                                    minLength={8}
                                    maxLength={250}
                                />
                                <span className="icon is-small is-left">
                                    <FontAwesomeIcon icon={faLock} />
                                </span>
                            </p>
                        </div>
                    )}

                    {(mode === Modes.Signup ||
                        mode === Modes.RecoveryOTP) && (
                            <div className="field">
                                <p className="control has-icons-left">
                                    <input
                                        name="repeatPassword"
                                        className="input"
                                        type="password"
                                        placeholder="Type password again"
                                        autoComplete="new-password"
                                        value={repeatPassword}
                                        onChange={(e) =>
                                            changeRepeatPassword(e.target.value)
                                        }
                                        required
                                        minLength={8}
                                        maxLength={250}
                                    />
                                    <span className="icon is-small is-left">
                                        <FontAwesomeIcon icon={faLock} />
                                    </span>
                                </p>
                            </div>
                        )}
                </section>
                <footer className="modal-card-foot">
                    <span>
                        <button
                            className="button is-primary"
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? (
                                <span>{messageLoading(mode)}</span>
                            ) : (
                                <div>
                                    <FontAwesomeIcon icon={faSignInAlt} />
                                    &nbsp;
                                    {message(mode)}
                                </div>
                            )}
                        </button>
                        <button
                            className="button"
                            onClick={(event) => {
                                event.preventDefault()
                                setModeHandler(Modes.Recovery)
                            }}
                        >
                            <FontAwesomeIcon icon={faUndo} />
                            &nbsp; Forgot password...
                        </button>
                        <button
                            className="button"
                            onClick={(event) => {
                                event.preventDefault()
                                setModeHandler(
                                    mode === Modes.Signup
                                        ? Modes.Login
                                        : Modes.Signup
                                )
                            }}
                        >
                            <FontAwesomeIcon icon={faKey} />
                            &nbsp;
                            {mode === Modes.Signup
                                ? 'Login...'
                                : 'Signup...'}
                        </button>
                    </span>
                </footer>
            </form>
        </div>
    )
}
