export default function SettingsButton(props: {
    link?: string
    clickEvent?: VoidFunction
    title: string
    text: string
}) {
    return (
        <a
            className="message is-dark"
            href={props.link}
            target="_blank"
            onClick={props.clickEvent}
            rel="noreferrer"
        >
            <div className="message-body">
                <div className="button is-pulled-right is-text">
                    <span className="fas fa-chevron-right" />
                </div>
                <h3 className="subtitle">{props.title}</h3>
                <p>{props.text}</p>
            </div>
            <hr />
        </a>
    )
}