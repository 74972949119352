export default function LoadConcToggle(props: {
    nitrogenConcentrationShown: boolean
    toggleNitrogenConcs: () => void
}) {
    return (
        <div className="buttons is-small has-addons is-centered">
            <button
                onClick={props.toggleNitrogenConcs}
                className={
                    `button ${!props.nitrogenConcentrationShown && `is-primary`}`
                }
            >
                <span>Show loads</span>
            </button>
            <button
                onClick={props.toggleNitrogenConcs}
                className={
                    `button ${props.nitrogenConcentrationShown && `is-primary`}`
                }
            >
                <span>Show concentrations</span>
            </button>
        </div>
    )
}
