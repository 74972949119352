/* 
    A UUIDv4 generator: https://www.ietf.org/rfc/rfc4122.txt
    Adapted from https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
*/
export default function UUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function isUUID(possibleUUID: string): boolean {
    const regexPattern = /[0-z]{8}-[0-z]{4}-[0-z]{4}-[0-z]{4}-[0-z]{12}/
    return regexPattern.test(possibleUUID)
}