import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import TimeSince from './TimeSince'
import { DataLocation, DataStream } from '../Redux/storeTypes'
import { newStore } from '../Redux/Store'
import ReactGA from 'react-ga4'
import {
    addFavourite,
    addNodeData,
    addVRG,
    loadState,
    login,
    removeFavourite,
    setCatchments,
    setDiscoveryLocations,
    setLatest,
    setLoadInProgress,
    setLocationLoaded,
    setLocations,
    setQualityCodes,
    setThresholds,
    showAlert,
    toggleLoginShown
} from '../Redux/NewReducers'

NProgress.configure({ showSpinner: false })
const backendBaseUri = process.env.REACT_APP_BACKEND + '/api'

export default class FetchData {
    /**
     * This class contains methods to fetch and populate
     * the state with all required data. This is the data access
     * layer, and should be written to be a completely replaceable API.
     */

    /* Static keys used to for local browser storage */
    public static LOCALSTORAGE_FAVOURITES_KEY = 'favourites'
    public static LOCALSTORAGE_EULA_KEY = 'eula'
    public static LOCALSTORAGE_SATELLITE_KEY = 'satellite'
    public static LOCALSTORAGE_VRG_KEY = 'VRGs'
    public static LOCALSTORAGE_VRG_MODAL_KEY = 'modalVRG'

    /**
     * Load a list of locations and all their associated nodes
     * then append it to state
     */
    public static async LoadLocations() {
        if (newStore.getState().locations.locations.length < 1) {
            const response = await this.Fetch(`${backendBaseUri}/locations`)
            const responseJson = await response.json()
            newStore.dispatch(
                setLocations(
                    responseJson.map((location: DataLocation) => {
                        return {
                            ...location,
                            nodes: location.nodes.map((node: DataStream) => {
                                return { ...node, data: null }
                            }),
                            loaded: false,
                        }
                    })
                )
            )
        }
        FetchData.LoadCatchments()
    }

    /**
     * Load the latest value for all nodes into state
     */
    public static async LoadLatest() {
        const latestRes = await this.Fetch(`${backendBaseUri}/latest`)
        const latestJson = await latestRes.json()
        newStore.dispatch(setLatest(latestJson))
    }

    /**
     * Sets the catchments for each VRG location
     */
    private static async LoadCatchments() {
        const catchmentRes = await this.Fetch(`${backendBaseUri}/catchments`)
        const catchmentJson = await catchmentRes.json()
        newStore.dispatch(setCatchments(catchmentJson))
        newStore.dispatch(
            setLocations(
                newStore.getState().locations.locations.map(
                    location => {
                        if (location.catchments === 'VRG') {
                            return {
                                ...location, catchments: this.PointToCatchment(
                                    location.lat,
                                    location.lng
                                )
                            }
                        }
                        return location
                    }
                )
            )
        )
    }

    /**
     * Return favourites from localstorage
     * Used by `LoadFavourites`
     */
    public static getLocalFavourites(): string[] {

        const local = window.localStorage.getItem(
            FetchData.LOCALSTORAGE_FAVOURITES_KEY
        )
        return local ? JSON.parse(local) : []
    }

    /**
     * Updates cloud favourite
     */
    public static async updateCloudFav(
        locationId: string,
        deleteFav: boolean,
        isFav: boolean,
        showError = true
    ) {
        const body = new FormData()
        body.append('location', locationId)
        body.append('is_fav', JSON.stringify(isFav))
        body.append('action', deleteFav ? 'delete' : 'update')

        const res = await this.Fetch(`${backendBaseUri}/favourite/`, {
            method: 'post',
            body: body
        })
        if (showError && res.status !== 200) {
            newStore.dispatch(showAlert(
                {
                    text: 'Error updating cloud favourite.',
                    is_error: true
                }
            ))
        }
        return res
    }

    /**
     * Subscribe or unsubscribe user
     */
    public static async subscribe(yes = true) {
        const body = new FormData()
        body.append('scope', '*')
        body.append('action', yes ? 'update' : 'delete')

        const res = await this.Fetch(`${backendBaseUri}/subscription/`, {
            method: 'post',
            body: body
        })
        if (res.status !== 200) {
            newStore.dispatch(showAlert({ text: 'Error subscribing.', is_error: true }))
        }
        return res
    }

    /**
     * Get a user's subscription(s)
     */
    public static async getSubscription() {
        return await (await this.Fetch(`${backendBaseUri}/subscription/`)).json();
    }

    /**
     * Adapted from https://stackoverflow.com/questions/31790344/determine-if-a-point-reside-inside-a-leaflet-polygon
     * @param point
     * @param polygon
     */
    private static isPointInsidePolygon(
        point: [number, number],
        polygon: [[number, number]]
    ) {
        const x = point[0],
            y = point[1]

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i][1],
                yi = polygon[i][0],
                xj = polygon[j][1],
                yj = polygon[j][0]

            const intersect =
                // eslint-disable-next-line no-mixed-operators
                yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
            if (intersect) {
                return true
            }
        }
        return false
    }

    /**
     * Given a point, will return what catchment it is in
     * Will get catchments and ensure persistence in local storage
     * and manage periodically updating catchments
     */
    public static PointToCatchment(lat: number, lng: number) {
        for (const catchment of newStore.getState().catchment.catchments) {
            try {
                if (
                    this.isPointInsidePolygon(
                        [lat, lng],
                        JSON.parse(catchment.shapefile).coordinates[0][0]
                    )
                )
                    return catchment.name
            } catch {
                console.log('Invalid catchment JSON: ', catchment.shapefile)
            }
        }
        return ''
    }

    /**
     * Get favourites from localstorage and  cloud fav api to add them to state
     */
    public static async LoadFavourites(isLoggedIn: boolean | undefined) {
        newStore.getState().favourites.favourites.forEach((f: any) =>
            newStore.dispatch(removeFavourite(f.locationId))
        )
        if (isLoggedIn) {
            const cloudFavs = await (await this.Fetch(`${backendBaseUri}/favourite/`)).json();
            if (cloudFavs) {
                cloudFavs
                    .filter((f: any) => f.is_fav)
                    .map((f: any) => f.location)
                    .forEach((l: any) => newStore.dispatch(addFavourite({ locationId: l, isLocal: true })))
            }
        }
        this.getLocalFavourites().forEach((l) => newStore.dispatch(addFavourite({ locationId: l, isLocal: true })))
    }

    private static TimeSeriesRequestMaker(
        node: DataStream
    ) {
        if (node.nodeIdentifier.includes('VRG')) {
            return this.GetTimeseriesDataForNode(
                node.nodeIdentifier,
                node.apiIdentifier,
                TimeSince.MonthsAgo(3),
                new Date()
            )
        } else if (node.name === 'GrabSample') {
            return this.GetTimeseriesDataForNode(
                node.nodeIdentifier,
                node.apiIdentifier,
                new Date(0, 0, 0),
                new Date()
            )
        }
        return this.GetTimeseriesDataForNode(
            node.nodeIdentifier,
            node.apiIdentifier,
            TimeSince.MonthsAgo(6),
            TimeSince.DaysAgo(-1)
        )
    }

    /**
     * Load all the node timeseries data for a given location into application state
     * Up to 6 months of data is loaded for each node
     * For VRGs, up to 3 months are loaded
     * @param locationId location to load node data for
     */
    public static async LoadNodes(locationId: number) {
        const regexNodeIdentifier = /\/([\w-]+)\?/
        if (
            (newStore.getState().locations.locations[locationId].loaded) ||
            newStore.getState().locations.locations[locationId].loadInProgress
        ) {
            return
        }
        newStore.dispatch(setLoadInProgress({ loadInProgress: true, locationId: locationId }))
        const nodes = JSON.parse(
            JSON.stringify(newStore.getState().locations.locations[locationId].nodes)
        )
        const responseArray: Response[] = []
        const isVrg: boolean = nodes.length && nodes[0].nodeIdentifier.includes('VRG')
        await Promise.all(
            nodes.map(async (node: DataStream) => {
                responseArray.push(
                    await this.TimeSeriesRequestMaker(node)
                )
            })
        )
        
        responseArray.forEach(async response => {
            if (response.status !== 200) {
                newStore.dispatch(showAlert(
                    {
                        text: 'There was an error loading this data. Please refresh the page or email 1622@csiro.au if the problem persists.',
                        is_error: true
                    }
                ))
                newStore.dispatch(addNodeData(
                    {
                        locationId: locationId,
                        nodeId: response.url.match(regexNodeIdentifier)[1],
                        data: []
                    }
                ))
                //ReactGA.exception({
                //    description: `Load failure (${response.statusText}): ${response.url.match(regexNodeIdentifier)[1]}`
                //}) This is currently commented out as react-ga4 does not support exceptions
                // One the library does support it, uncomment this code and remove the exception code below
                ReactGA.event({
                    category: 'Sensor Load',
                    action: 'Load failure',
                    label: `(${response.statusText}): ${response.url.match(regexNodeIdentifier)[1]}`,
                })
            } else {
                const responseJson = await response.json()                
                const responseDataVar = Array.isArray(responseJson) ? responseJson : (isVrg
                    ? responseJson.body.map((dataPoint: any) => [
                        `${dataPoint.start_time}Z`,
                        dataPoint.value < 0
                            ? 0
                            : parseFloat(dataPoint.value.toFixed(1)),
                    ])
                    : responseJson.data)
                if (responseDataVar?.[0]?.compound) {
                    newStore.dispatch(addNodeData(
                        {
                            locationId: locationId,
                            nodeId: response.url.match(regexNodeIdentifier)[1],
                            data: responseDataVar
                                .flat()
                                .sort((a: string, b: string) => (a[0] > b[0] ? 1 : -1))
                                .map((datapoint: any) => [datapoint.date.replaceAll('"', ''), datapoint.data, 0]),
                            unit: responseDataVar[0].compound,
                            display: responseDataVar[0].display
                        }
                    ))
                } else {
                    newStore.dispatch(
                        addNodeData(
                            {
                                locationId: locationId,
                                nodeId: response.url.match(regexNodeIdentifier)[1],
                                data: responseDataVar
                                    .sort((a: string, b: string) => (a[0] > b[0] ? 1 : -1))
                            }
                        )
                    )
                }
            }
        })
        newStore.dispatch(setLoadInProgress({ loadInProgress: false, locationId: locationId }))
        newStore.dispatch(setLocationLoaded(locationId))
        ReactGA.event({
            category: 'Sensor',
            action: 'Sensor location loaded',
            label: newStore.getState().locations.locations[locationId].name,
        })

        /*
            User data view tracking
        */
        const currentLocationId = newStore.getState().locations.locations[locationId]?.id
        if (currentLocationId && newStore.getState().locations.email !== '' && newStore.getState().locations.locations[locationId]?.nodes[0].unit !== 'mm') {
            FetchData.updateCloudFav(
                currentLocationId,
                false,
                false,
                false
            )
        }
    }

    /**
     * Used to obtain the logged-on user's email after loading the app
     * This is required since log-in state is stored purely on the back-end
     * @param state state to add logged-in user email to
     */
    public static async SetEmail(): Promise<any> {
        const response = await this.Fetch(`${backendBaseUri}/login/`)
        let responseJson
        if (response.status === 200) {
            responseJson = await response.json()
            newStore.dispatch(login(
                {
                    email: responseJson.email,
                    staff: responseJson.staff
                }
            ))
        }
        newStore.dispatch(loadState())
        return responseJson
    }

    /**
     * Attept to authenticate a user with the back-end
     * @param email the email to authenticate with
     * @param password the password to authenticate with
     */
    public static async Login(
        email: string,
        password: string
    ): Promise<Response> {
        const body = new FormData()
        body.append('email', email)
        body.append('password', password)
        let response = await this.Fetch(`${backendBaseUri}/login/`, {
            method: 'post',
            body: body,
        })
        if (response.status === 200) {
            const responseJson = await response.json()
            newStore.dispatch(login(
                {
                    email: responseJson.email,
                    staff: responseJson.staff
                }))
            newStore.dispatch(toggleLoginShown())
            window.location.reload()
        } else if (response.status === 401) {
            newStore.dispatch(showAlert(
                {
                    text: 'Wrong email or password, please try again',
                    is_error: true
                }
            ))
        }
        return response
    }

    /**
     * Attept to register a new user with the back-end
     * @param state the state to add authentication result to in case of success,
     *  and to show alerts
     * @param email the email to register with
     * @param password the password to register with
     */
    public static async Signup(
        email: string,
        password: string
    ): Promise<Response> {
        const body = new FormData()
        body.append('email', email)
        body.append('password', password)
        const response = await this.Fetch(`${backendBaseUri}/signup/`, {
            method: 'post',
            body: body,
        })
        if (response.status === 200) {
            const responseJson = await response.json()
            newStore.dispatch(login({ email: responseJson.email, staff: responseJson.staff }))
            newStore.dispatch(toggleLoginShown())
            window.location.reload()
            await this.LoadLocations()
        } else {
            newStore.dispatch(showAlert(
                {
                    text: 'Error signing up, please try again later',
                    is_error: true
                }
            ))
        }
        return response
    }

    /**
     * Trigger password recovery for an account
     * @param state Used to show alerts only
     * @param email email to trigger password recovery for
     */
    public static async Recovery(
        email: string
    ): Promise<Response> {
        const body = new FormData()
        body.append('email', email)
        let res = await this.Fetch(`${backendBaseUri}/reset/`, {
            method: 'post',
            body: body,
        })
        if (res.status === 200) {
            res = await res.json()
            newStore.dispatch(showAlert(
                {
                    text: 'Password recovery email has been sent to ' + email,
                    is_error: false
                }
            ))
        } else {
            newStore.dispatch(showAlert(
                {
                    text: 'Error sending code, please check the email you typed',
                    is_error: true
                }
            ))
        }
        return res
    }

    /**
     * Used to compelete the password recovery process. The user upon triggering
     * password recovery would recieve an OTP that is used by this function
     * to allow setting a new password
     * @param email email to reset password for
     * @param otp OTP password (emailed to account, verified by back-end)
     * @param password new password requested to be set
     */
    public static async RecoveryOtp(
        email: string,
        otp: string,
        password: string
    ): Promise<Response> {
        const body = new FormData()
        body.append('email', email)
        body.append('otp', otp)
        body.append('password', password)
        let response = await this.Fetch(`${backendBaseUri}/reset/`, {
            method: 'post',
            body: body,
        })
        if (response.status === 200) {
            let responseJson = await response.json()
            newStore.dispatch(login({ email: responseJson.email, staff: responseJson.staff }))
            newStore.dispatch(toggleLoginShown())
            newStore.dispatch(showAlert({ text: 'Password sucessfully changed!', is_error: false }))
        } else {
            newStore.dispatch(showAlert({ text: 'Error validating code, please try again', is_error: true }))
        }
        return response
    }

    /**
     * Submit feedback for storage
     */
    public static async SubmitFeedback(rating: string, feedback: string, name: string, email: string): Promise<Response> {
        const body = new FormData()
        body.append('rating', rating)
        body.append('feedback', feedback)
        body.append('name', name)
        body.append('email', email)
        const res = await this.Fetch(`${backendBaseUri}/feedback/`, {
            method: 'post',
            body: body
        })
        if (res.status === 200) {
            newStore.dispatch(
                showAlert(
                    {
                        text: 'Feedback submitted successfully!',
                        is_error: false
                    }
                ))
        } else {
            newStore.dispatch(
                showAlert(
                    {
                        text: 'Error submitting, please try again or email us.',
                        is_error: true
                    }
                ))
        }
        return res
    }

    /**
     * Submit importer input
     * @param state not modified
     */
    public static async SubmitImport(location: string, compounds: string, data: File, format: string):
        Promise<{ ok: boolean, message?: string, updated_grab_locations?: { [loc: string]: number } }> {
        const body = new FormData()
        body.append('location', location)
        body.append('compounds', compounds)
        body.append('file', data)
        body.append('format', format)
        const res = await this.Fetch(`${backendBaseUri}/importer/`, {
            method: 'post',
            body: body
        }, { globalError: false })
        return res.json()
    }

    /**
     * Trigger logging out user with the backend
     * The browser is reloaded in case of success to ensure correct UI state
     * @param state used to set logged out state in case of success
     */
    public static async Logout(): Promise<Response> {
        const res = await this.Fetch(`${backendBaseUri}/logout/`, {
            method: 'post',
        })
        if (res.status === 200) {
            newStore.dispatch(login({ email: '', staff: false }))
            window.location.reload()
        }
        return res
    }

    /**
        Get timeseries data stream for an eagle.io node
        starting at an earlier date `startTime` to now.
        Nodes requiring the QLD Gov use `isQldGov`
    */
    private static async GetTimeseriesDataForNode(
        nodeIdentifier: string,
        apiIdentifier: string,
        startTime: Date,
        endTime: Date
    ): Promise<Response> {
        const requestURL = nodeIdentifier.includes('VRG')
            ? `${backendBaseUri}/stream/${nodeIdentifier}` +
            `&start_time=${startTime.toISOString()}`
            : `${backendBaseUri}/stream/${nodeIdentifier}` +
            `?start_time=${startTime.toISOString()}` +
            `&end_time=${endTime.toISOString()}` +
            `&api_identifier=${apiIdentifier}`

        NProgress.start()
        const res = await fetch(requestURL, { credentials: 'include' })
        NProgress.done()
        return res
    }

    /**
       Get list of locations based on a search term from the Mabox API
    */
    public static async GetLocationsFromSearch(
        searchTerm: string
    ): Promise<any> {
        NProgress.start()
        try {
            const res = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/
            ${searchTerm}.json?country=au&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&language=en`)
            if (res.status === 401) {
                NProgress.done()
                return res
            }
            if (![200, 404].includes(res.status)) {
                throw res
            }
            NProgress.done()
            const responseJson = await res.json()
            return responseJson
        } catch (error) {
            NProgress.done()
            newStore.dispatch(showAlert(
                {
                    text: 'Error connecting to server, please check your connection',
                    is_error: true
                })
            )
            return error
        }
    }

    /**
        A version of fetch() that checks for errors before returning
        If an error was found during the request, the user is directed to
        an error page with further details
        Additionally triggers displaying loading indicator

        {options.globalError}: Show error alert if request fails (default true)
    */
    private static async Fetch(
        uri: string,
        params?: any,
        options?: {
            globalError: boolean
        }
    ): Promise<Response> {
        NProgress.start()
        try {
            const res = await fetch(uri, { ...params, credentials: 'include' })
            if (res.status === 401) {
                NProgress.done()
                return res
            }
            if (![200, 404].includes(res.status)) {
                throw res
            }
            NProgress.done()
            return res
        } catch (error) {
            NProgress.done()
            console.log(error)
            if (options?.globalError !== false) {
                newStore.dispatch(showAlert(
                    {
                        text: 'Error loading, please reload webpage',
                        is_error: true
                    }
                ))
            }
            return error as any
        }
    }

    /**
     * Searches localstorage for keys containing 'VRG' and then adds that
     * saved VRG information to the store
     */
    public static addLocalStorageVRGs() {
        if (newStore.getState().locations.email === '')
            if (localStorage.getItem(this.LOCALSTORAGE_VRG_KEY)) {
                JSON.parse(
                    localStorage.getItem(this.LOCALSTORAGE_VRG_KEY)
                ).forEach((vrg: DataLocation) => newStore.dispatch(addVRG(vrg)))
            }
    }

    /**
     * Load the thresholds
     */
    public static async LoadThresholds(): Promise<void> {
        const res = await this.Fetch(backendBaseUri + '/thresholds')
        const resJson = await res.json()
        newStore.dispatch(setThresholds(resJson))
    }

    /**
     * Load the quality codes
     */
    public static async LoadQualityCodes(): Promise<void> {
        const res = await this.Fetch(backendBaseUri + '/quality_codes')
        const resJson = await res.json()
        newStore.dispatch(setQualityCodes(resJson))
    }

    /**
     * Updates saved VRGs
     * If the user is not logged in, then the VRG is updated in local storage
     * If the user is logged in, then the VRG is updated on the server side
     */
    public static async updateStoredVRGs(
        newVRG: DataLocation,
        action: string,
        email?: string,
        locations?: DataLocation[]
    ) {
        if (email === '') {
            const vrgs = locations.filter(
                (location: DataLocation) => location.locationIdentifier === 'VRG'
            )
            localStorage.setItem(
                FetchData.LOCALSTORAGE_VRG_KEY,
                JSON.stringify(vrgs)
            )
        } else {
            this.updateServerVRG(newVRG, action)
        }
    }

    public static async updateStoredGrabs(
        newGrab: DataLocation,
        action: string
    ) {
        const body = new FormData()
        body.append('action', action)
        body.append('identifier', newGrab.locationIdentifier)
        body.append('name', newGrab.name)
        body.append('organisation', newGrab.organisation)
        body.append('lat', newGrab.lat.toString())
        body.append('lng', newGrab.lng.toString())
        body.append('catchment', newGrab.catchments)

        await this.Fetch(`${backendBaseUri}/grab_locations/`, {
            method: 'post',
            body: body,
        })
    }

    private static async updateServerVRG(
        newVRG: DataLocation,
        action: string
    ) {
        const body = new FormData()
        body.append('action', action)
        body.append('name', newVRG.name)
        body.append('lat', newVRG.lat.toString())
        body.append('lng', newVRG.lng.toString())
        await this.Fetch(`${backendBaseUri}/vrgs/`, {
            method: 'post',
            body: body,
        })
    }

    public static async updateGrabSampleName(
        name: string,
        locationIdentifier: string
    ) {
        const body = new FormData()
        body.append('name', name)
        body.append('location', locationIdentifier)
        this.Fetch(`${backendBaseUri}/grabs/`, {
            method: 'post',
            body: body,
        })
    }

    public static async getDiscoveryLocations() {
        const res = await this.Fetch(`${backendBaseUri}/discovery`)
        const resJson = await res.json()
        newStore.dispatch(setDiscoveryLocations(resJson))
    }

    public static async postDiscoveryLocations(locationsJson: string) {
        const body = new FormData()
        body.append('locationsJson', locationsJson)
        const response = await this.Fetch(`${backendBaseUri}/discovery`, {
            method: 'post',
            body: body,
        })
        const stringRepresentation = await response.text()
        newStore.dispatch(showAlert({ text: stringRepresentation, is_error: !response.ok }))
    }
}
