import { useEffect, useState } from "react";
import { setDiscoveryShown } from "../Redux/NewReducers";
import { useAppDispatch, useAppSelect } from "../Redux/Store";
import FetchData from "../Services/FetchData";
import DiscoveryLocation from "./DiscoveryLocation";

export default function DiscoveryImporter() {
    const [isLoading, setIsLoading] = useState(false)
    const discoveryLocations = useAppSelect(state => state.discovery.discoveryLocations)
    const [isActiveArray, setIsActiveArray] = useState([])
    const currentLocations = useAppSelect(state => state.locations.locations)
    const [hasLoadedDiscoveryLocations, setHasLoadedDiscoveryLocations] = useState(discoveryLocations.length > 0)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!hasLoadedDiscoveryLocations) {
            FetchData.getDiscoveryLocations()
            setIsLoading(true)
            setHasLoadedDiscoveryLocations(true)
        }
        if (isLoading && discoveryLocations.length > 0) {
            setIsLoading(false)
        }
        if (discoveryLocations.length > 0 && isActiveArray.length === 0) {
            const newArray: any = []
            discoveryLocations.forEach(location => newArray.push([false, new Array(location.child_streams.length).fill(false)]))
            setIsActiveArray(newArray)
        }
    }, [hasLoadedDiscoveryLocations, isLoading, discoveryLocations, isActiveArray.length])

    function toggleSelectedLocation(locationIndex: number) {
        const newArray = [...isActiveArray]
        newArray[locationIndex][0] = !isActiveArray[locationIndex][0]
        setIsActiveArray(newArray)
    }

    function toggleSelectedStream(locationIndex: number, streamIndex: number) {
        const newArray = [...isActiveArray]
        newArray[locationIndex][1][streamIndex] = !isActiveArray[locationIndex][1][streamIndex]
        setIsActiveArray(newArray)
    }

    function submitSensors() {
        let locationsToSubmit = JSON.parse(JSON.stringify(discoveryLocations))
        locationsToSubmit.forEach((location: any, locationIndex: number) => location.child_streams = location.child_streams.filter((_stream: any, streamIndex: number) => isActiveArray[locationIndex][1][streamIndex]))
        locationsToSubmit = locationsToSubmit.filter((_location: any, index: number) => isActiveArray[index][0])
        if (locationsToSubmit.length > 0) {
            console.log(JSON.stringify(locationsToSubmit))
            FetchData.postDiscoveryLocations(JSON.stringify(locationsToSubmit))
        }
    }

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={() => dispatch(setDiscoveryShown(false))} />
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Eagle.io sensor importer</p>
                    <button className="delete" onClick={() => dispatch(setDiscoveryShown(false))} />
                </header>
                <section className="modal-card-body">
                    <div>
                        {discoveryLocations.map((location, index) => DiscoveryLocation(
                            location,
                            () => toggleSelectedLocation(index),
                            isActiveArray,
                            currentLocations,
                            index,
                            (streamIndex: number) => toggleSelectedStream(index, streamIndex)
                        ))}
                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className={`button is-primary ${isLoading && `is-loading`}`} onClick={() => submitSensors()}>Import selected sensors</button>
                    <button className="button" onClick={() => dispatch(setDiscoveryShown(false))} >Cancel</button>
                </footer>
            </div>
        </div>
    )
}