import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import { toggleFeedbackShown } from '../Redux/NewReducers'
import { useAppDispatch, useAppSelect } from '../Redux/Store'
import FetchData from '../Services/FetchData'

export default function Feedback() {
    const feedbackShown = useAppSelect(state => state.ui.feedbackShown)
    const dispatch = useAppDispatch()
    const [rating, setRating] = useState('5')
    const [feedback, setFeedback] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [isLoading, setLoadingStatus] = useState(false)

    async function formSubmitted(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        setLoadingStatus(true)
        await FetchData.SubmitFeedback(rating, feedback, name, email)
        setLoadingStatus(false)
        dispatch(toggleFeedbackShown(false))
    }

    useEffect(() => {
        const launchIndex = localStorage.getItem('launchIndex') ?? '1'
        localStorage.setItem('launchIndex', String(Number(launchIndex) + 1))
    })

    return feedbackShown && (
        <div className="modal is-active">
            <div className="modal-background" onClick={() => dispatch(toggleFeedbackShown(false))}></div>
            <div className="modal-card">
                <form onSubmit={(e) => formSubmitted(e)}>
                    <header className="modal-card-head">
                        <p className="modal-card-title">How's your experience been?</p>
                        <button type="button" className="delete" aria-label="close" onClick={() => dispatch(toggleFeedbackShown(false))}></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Rating</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">

                                        <fieldset className="starability-checkmark" style={{ minHeight: 'unset' }} name="rating" onChange={(event: ChangeEvent<any>) => setRating(event.target.value)}>
                                            <input type="radio" id="no-rate" className="input-no-rate" name="rating" value="5" />
                                            <input type="radio" id="first-rate1" name="rating" value="1" />
                                            <label htmlFor="first-rate1" title="Terrible">1 star</label>
                                            <input type="radio" id="first-rate2" name="rating" value="2" />
                                            <label htmlFor="first-rate2" title="Not good">2 stars</label>
                                            <input type="radio" id="first-rate3" name="rating" value="3" />
                                            <label htmlFor="first-rate3" title="Average">3 stars</label>
                                            <input type="radio" id="first-rate4" name="rating" value="4" />
                                            <label htmlFor="first-rate4" title="Very good">4 stars</label>
                                            <input type="radio" id="first-rate5" name="rating" value="5" />
                                            <label htmlFor="first-rate5" title="Amazing">5 stars</label>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Feedback</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <textarea className="textarea" placeholder="(optional) Any feedback at all would be appreciated!" name="feedback" onChange={(event) => setFeedback(event.target.value)}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Name</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="text" placeholder="(optional) e.g John Smith" name="name" onChange={(event) => setName(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">Email</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <div className="control">
                                        <input className="input" type="email" placeholder="(optional) e.g. johnsmith@email.com" name="email" onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-success is-pulled-right" type="submit" disabled={isLoading}
                        >{isLoading ? 'Sending...' : 'Send feedback'}</button>
                        <button type="button" className="button" onClick={() => dispatch(toggleFeedbackShown(false))}>Cancel</button>
                    </footer>
                </form>
            </div>
        </div>
    )
}