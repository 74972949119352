import ReactLoading from 'react-loading'
import { useAppSelect } from '../../Redux/Store'

export default function LoadingPage() {
    const mobileLocationsShown = useAppSelect(state => state.ui.mobileLocationsShown)
    return (
        <section className="hero is-bold is-fullheight has-background-light">
            <div className="hero-body columns is-centered">
                <div className="column is-10">
                    <h1 className="title">Loading 1622™ WQ sensors</h1>
                    <h2 className="subtitle">
                        The initial load will be longer than subsequent
                        loads
                    </h2>
                    <ReactLoading
                        type={'bars'}
                        color={'0C66A1'}
                        height={'25vh'}
                        width={mobileLocationsShown ? '50vw' : '20vw'}
                    />
                </div>

            </div>
        </section>
    )
}
