export default class TimeSince {
    /**
    Get date N month ago
    */
    public static MonthsAgo(n: number) {
        const date = new Date()
        date.setMonth(date.getMonth() - n)
        return date
    }

    /**
    Get date n days ago
    */
    public static DaysAgo(n: number) {
        const date = new Date()
        date.setDate(date.getDate() - n)
        return date
    }

    /**
    Get date at N hour today
    */
   public static DateAtHour(n: number) {
    const date = new Date()
    date.setHours(n)
    return date
}
}
