import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'whatwg-fetch'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './Components/App/App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { newStore } from './Redux/Store'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { init } from "@sentry/react";

const environment = process.env.ENVIRONMENT || 'development'
init({ dsn: "https://331c6859be4e42b09626ee7fe11a62dd@o682283.ingest.sentry.io/5770946", environment: environment })

ReactDOM.render(
    <Provider store={newStore}>
        <Router>
            <Route path="/" component={App} />
        </Router>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)
window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
})
