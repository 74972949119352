import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
    alertSlice,
    catchmentSlice,
    dateZoomStatusSlice,
    discoverySlice,
    favouritesSlice,
    highlightSlice,
    loadStateSlice,
    locationsSlice,
    tempGrabSlice,
    tempVRGSlice,
    thresholdsSlice,
    uiSlice
} from './NewReducers'

export const newStore = configureStore({
    reducer: {
        alert: alertSlice.reducer,
        locations: locationsSlice.reducer,
        ui: uiSlice.reducer,
        favourites: favouritesSlice.reducer,
        stateLoad: loadStateSlice.reducer,
        highlight: highlightSlice.reducer,
        dateZoomStatus: dateZoomStatusSlice.reducer,
        tempVRG: tempVRGSlice.reducer,
        tempGrab: tempGrabSlice.reducer,
        threshold: thresholdsSlice.reducer,
        discovery: discoverySlice.reducer,
        catchment: catchmentSlice.reducer,
    }
})

export type RootState = ReturnType<typeof newStore.getState>
export type AppDispatch = typeof newStore.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelect: TypedUseSelectorHook<RootState> = useSelector