import React from 'react'
import StreamIcon from '../Assets/RiverHeight.svg'
import FavouritesIcon from '../Assets/FavouritesIcon.svg'
import LoadsAvailable from '../Assets/LoadsAvailable.svg'
import RainAvailable from '../Assets/RainAvailable.svg'
import ConcAvailable from '../Assets/ConcAvailable.svg'
import LoadsMissing from '../Assets/LoadsMissing.svg'
import RainMissing from '../Assets/RainMissing.svg'
import ConcMissing from '../Assets/ConcMissing.svg'
import GeneralMissing from '../Assets/GeneralMissing.svg'
import MoreAvailable from '../Assets/MoreAvailable.svg'
import HollowFav from '../Assets/FavsHollow.svg'
import VRG from '../Assets/VirtualRainGauge.svg'
import L, { Icon, IconOptions } from 'leaflet'
import PestAvailable from '../Assets/PestAvailable.svg'
import PestMissing from '../Assets/PestMissing.svg'

export function getMapIcon(
    iconString: string,
    highlighted: boolean
): Icon<IconOptions> {
    let icon = StreamIcon
    let iconSize = 40
    switch (iconString) {
        case 'loads-avail':
            icon = LoadsAvailable
            break
        case 'loads-missing':
            icon = LoadsMissing
            break
        case 'conc-avail':
            icon = ConcAvailable
            break
        case 'conc-missing':
            icon = ConcMissing
            break
        case 'general-missing':
            icon = GeneralMissing
            break
        case 'rain-avail':
            icon = RainAvailable
            iconSize = 48
            break
        case 'rain-missing':
            icon = RainMissing
            iconSize = 52
            break
        case 'VRG':
            iconSize = 48
            icon = VRG
            break
        case 'pesticide-avail':
            icon = PestAvailable
            break
        case 'pesticide-missing':
            icon = PestMissing
            break
    }
    return L.icon({
        iconUrl: icon,
        className: highlighted ? 'highlighted' : 'not-highlighted',
        iconSize: [iconSize, iconSize],
        iconAnchor: [20, 20],
        popupAnchor: [0, 0],
    })
}

function svgIcons(name: string) {
    let icon = ''
    let color = ''
    let id = ''
    let newIcon = false
    const lowerCaseNameIncludes = (checkString: string) =>
        name.toLowerCase().includes(checkString)
    let iconSize = '23px'
    if (lowerCaseNameIncludes('stream') || lowerCaseNameIncludes('river')) {
        icon = StreamIcon
        color = 'purple'
    }
    if (lowerCaseNameIncludes('favourites')) {
        icon = FavouritesIcon
        color = 'gold'
        id = 'favourited'
    }
    if (lowerCaseNameIncludes('hollow')) {
        icon = HollowFav
        id = 'notFavourited'
    }
    if (lowerCaseNameIncludes('loads-avail')) {
        icon = LoadsAvailable
    }
    if (lowerCaseNameIncludes('loads-missing')) {
        icon = LoadsMissing
    }
    if (lowerCaseNameIncludes('rain-avail')) {
        icon = RainAvailable
        iconSize = '30px'
        newIcon = true
    }
    if (lowerCaseNameIncludes('rain-missing')) {
        icon = RainMissing
        iconSize = '30px'
        newIcon = true
    }
    if (lowerCaseNameIncludes('conc-avail')) {
        icon = ConcAvailable
    }
    if (lowerCaseNameIncludes('conc-missing')) {
        icon = ConcMissing
    }
    if (lowerCaseNameIncludes('general-missing')) {
        icon = GeneralMissing
    }
    if (lowerCaseNameIncludes('more')) {
        icon = MoreAvailable
    }
    if (lowerCaseNameIncludes('vrg')) {
        icon = VRG
        iconSize = '30px'
        newIcon = true
    }
    if (lowerCaseNameIncludes('pesticide-avail')) {
        icon = PestAvailable
    }
    if (lowerCaseNameIncludes('pesticide-missing')) {
        icon = PestMissing
    }

    return (
        <span className="inlineFlex">
            {!newIcon && <div>&nbsp;</div>}
            <img
                className={`dai ${id}`}
                src={icon}
                alt={name}
                style={{ fill: color, height: iconSize, width: iconSize }}
            />
            {!newIcon && <div>&nbsp;</div>}
        </span>
    )
}

export default svgIcons
