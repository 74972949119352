import { Fragment, useEffect, useState } from 'react'
import FetchData from '../../../Services/FetchData'
import ReactGA from 'react-ga4'
import Favourite from '../../Favourite'
import { DataLocation } from '../../../Redux/storeTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSearch,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import DataAvailabilityIndicator from '../../../Services/DataAvailabilityIndicator'
import { alphabet } from '../../../Assets/ReuseableStringCons'
import './LocationListPanel.scss'
import LoadingPage from '../LoadingPage'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import AsyncButton from '../../AsyncButton'
import { isUUID } from '../../../Services/UuidGenerator'
import locationClickAnalytics from '../../../Services/GoogleAnalyticsEvents'
import { useAppDispatch, useAppSelect } from '../../../Redux/Store'
import { removeVRG, toggleDataShown, toggleLocationsShown, toggleMobileLocationsShown } from '../../../Redux/NewReducers'

export default function LocationListPanel() {
    const [search, setSearch] = useState('')
    const [subscribed, setSubscribed] = useState(false)
    const [loading, setLoading] = useState(true)
    const [typeFilter, setTypeFilter] = useState<'all' | 'loads' | 'concs' | 'rain' | 'pesticide'>('all')
    const [searchIcon, setSearchIcon] = useState(faSearch)
    const [sortAlpha, setSortAlpha] = useState(true)
    const locations = useAppSelect(state => state.locations.locations)
    const email = useAppSelect(state => state.locations.email)
    const favourites = useAppSelect(state => state.favourites.favourites)
    const mobileLocationsShown = useAppSelect(state => state.ui.mobileLocationsShown)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const _setSubscribed = async () => {
            let subs = await FetchData.getSubscription()
            setSubscribed(!!subs.length)
            setLoading(false)
        };

        _setSubscribed();
    }, []);

    function filteredLocations(): [DataLocation, number][] {
        const typedArray: [
            DataLocation,
            number
        ][] = locations.map((location, index) => [location, index])
        const filteredByName = typedArray.filter((location) =>
            locationFilter(location[0])
        )
        const filteredByType = filteredByName.filter((location) =>
            sensorFilter(location[0])
        )
        return filteredByType
    }

    function searchChangeHandler(text: string): void {
        setSearch(text)
        setSearchIcon(text.length > 0 ? faTimesCircle : faSearch)
    }

    async function updateSubscribed(value: boolean) {
        await FetchData.subscribe(value)

        setSubscribed(!!(await FetchData.getSubscription()).length)
    }

    /**
     * Renders a location row in the location list panel
     * @param location The location to be rendered
     */
    function renderLocationRow(location: [DataLocation, number]) {
        return (
            <div className="badge_WIP-parent is-clickable" key={location[1]}>
                <div
                    className="panel-block locationBlock"
                    onClick={async () => {
                        if (mobileLocationsShown) {
                            dispatch(toggleLocationsShown(false))
                            dispatch(toggleMobileLocationsShown(false))
                        }
                        await locationClicked(location[1], true)
                    }}
                    key={location[1]}
                >
                    {location[0].locationIdentifier === 'VRG' ? (
                        <AsyncButton
                            buttonClassName={
                                'button has-text-danger ml-4 is-pulled-right pr-5 pl-5'
                            }
                            loadingText={'...'}
                            onClickAsync={async () => {
                                FetchData.updateStoredVRGs(location[0], 'remove', email, locations)
                                dispatch(removeVRG(location[0]))
                                dispatch(toggleDataShown({ shouldShow: false, urlId: null, locationId: null, pushHistory: false }))
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </AsyncButton>
                    ) : (
                        <Favourite
                            locationId={location[0].id}
                        />
                    )}
                    <div className="sensor-indicator is-pulled-right">
                        {getIconText(location[0])}
                    </div>
                    <div>{location[0].name.trim()}</div>
                    <div className="locationListLocation">
                        {location[0].catchments ? location[0].catchments : ''}
                    </div>
                </div>
                <span className="badge_WIP" style={{ right: '0', left: '1px' }}></span>
            </div>
        )
    }

    function getIconText(location: DataLocation) {
        if (location.nodes.some((node) => node.unit === 'ug/L')) {
            return DataAvailabilityIndicator('pesticide-avail')
        }
        if (location.nodes.some((node) => node.name.includes('loads'))) {
            return DataAvailabilityIndicator('loads-avail-raw')
        } else if (
            location.nodes.some((node) => node.name.includes('concentrations'))
        ) {
            return DataAvailabilityIndicator('conc-avail-raw')
        } else if (
            location.nodes.some((node) => node.name.includes('Rainfall'))
        ) {
            return DataAvailabilityIndicator('rain-avail-raw')
        } else if (location.locationIdentifier === 'VRG') {
            return DataAvailabilityIndicator('VRG-raw')
        } else if (isUUID(location.locationIdentifier)) {
            return DataAvailabilityIndicator('pesticide-missing')
        }
        return DataAvailabilityIndicator('stream')
    }

    const locationClicked = async (
        locationId: number,
        pushHistory: boolean
    ) => {
        dispatch(toggleDataShown({ shouldShow: true, urlId: locations[locationId].id, locationId: locationId, pushHistory: pushHistory }))
        ReactGA.event({
            category: 'Data',
            action: 'Sensor clicked from sensor list',
            label: 'Sensor clicked from sensor list',
        })
        locationClickAnalytics(locations[locationId].nodes)
        await FetchData.LoadNodes(locationId)
    }

    /**
     * Renders a row in the local list panel for each VRG under the Virtual Rain Gauge section
     */
    function renderListVRG() {
        return (
            <Fragment>
                <p className="panel-heading">Virtual Rain Gauges</p>
                {filteredLocations()
                    .filter(
                        (location) => location[0].locationIdentifier === 'VRG'
                    )
                    .map((location) => renderLocationRow(location))}
            </Fragment>
        )
    }

    /**
     * Renders an alphabetically sorted list of all locations
     */
    function renderAlphabeticalList() {
        return alphabet
            .filter((letter) =>
                filteredLocations().some(
                    (location) =>
                        location[0].name.trim().toUpperCase().slice(0, 1) === letter &&
                        !favourites
                            .map((fav) => fav.locationId)
                            .includes(location[0].id)
                )
            )
            .map((letter, index) => (
                <div key={index}>
                    <p className="panel-heading">{letter}</p>
                    {filteredLocations()
                        .filter(
                            (location) =>
                                location[0].name.trim().slice(0, 1).toUpperCase() ===
                                letter &&
                                !favourites
                                    .map((fav) => fav.locationId)
                                    .includes(location[0].id)
                        )
                        .map((location) => renderLocationRow(location))}
                </div>
            ))
    }

    /**
     * Renders a list of locations that do not begin with a letter of the alphabet
     */
    function renderNonAlphaLocations() {
        const letterRegEx = /[A-z]/
        const listOfNonAlphaLocations = filteredLocations().filter(location =>
            !letterRegEx.test(location[0].name.trim().slice(0, 1)) &&
            !favourites.map((fav) => fav.locationId).includes(location[0].id)
        ).sort(
            (firstLocation, secondLocation) =>
                firstLocation[0].name.localeCompare(secondLocation[0].name)
        )
        if (listOfNonAlphaLocations.length > 0 && sortAlpha) {
            return (
                <div key="notAlpha">
                    <p className="panel-heading">Misc</p>
                    {listOfNonAlphaLocations.map(location => renderLocationRow(location))}
                </div>
            )
        }
    }

    function locationFilter(location: DataLocation): boolean {
        if (
            location.name.toLowerCase().includes(search.toLowerCase()) ||
            search.toLowerCase().includes(location.name.toLowerCase())
        ) {
            return true
        }
        return false
    }

    function sensorFilter(dataLocation: DataLocation): boolean {
        if (typeFilter.includes('all') ||
            (typeFilter.includes('rain') && dataLocation.nodes[0]?.unit === 'mm') ||
            (typeFilter.includes('concs') && dataLocation.nodes.some((node) => node?.unit === 'mg/L') &&
                !dataLocation.nodes.some(
                    (node) => node?.unit === 'tonnes/day'
                )) ||
            (typeFilter.includes('loads') && dataLocation.nodes.some((node) => node?.unit === 'tonnes/day')) ||
            (typeFilter.includes('pesticide') && dataLocation.nodes.some((node) => node?.unit === 'ug/L'))
        ) {
            return true
        }
        return false
    }

    /**
     * Renders a list of all locations grouped together by their catchment
     */
    function renderCatchmentList() {
        let catchmentSet: Set<string> = new Set()
        locations.forEach((location) => {
            if (location.catchments?.[0] &&
                !favourites.map((fav) => fav.locationId).includes(location.id) &&
                !favourites.map((fav) => fav.locationId).includes(location.locationIdentifier)) {
                catchmentSet.add(location.catchments[0])
            } else {
                catchmentSet.add('[]')
            }
        })
        return [...catchmentSet]
            .sort()
            .map((catchment) => {
                return {
                    locations: filteredLocations()
                        .filter(
                            (location) =>
                                !favourites.map((fav) => fav.locationId).includes(location[0].id) &&
                                !favourites.map((fav) => fav.locationId).includes(location[0].locationIdentifier) &&
                                (
                                    location[0]?.catchments?.[0] === catchment ||
                                    (
                                        (location[0]?.catchments?.length === 0 || location[0]?.catchments === null) &&
                                        catchment === '[]'
                                    )
                                )
                        )
                        .map((location) => renderLocationRow(location)),
                    catchment: catchment,
                }
            })
            .filter(
                (locationCatchment) => locationCatchment.locations.length >= 1
            )
            .map((locationCatchment, index) => (
                <div key={index}>
                    <p className="panel-heading">
                        {locationCatchment.catchment === '[]'
                            ? 'No Catchment'
                            : locationCatchment.catchment}
                    </p>
                    {locationCatchment.locations}
                </div>
            ))
    }

    useEffect(() => {
        window.onpopstate = () => {
            locationClicked(
                Number(document.location.hash.replace('#', '')),
                false
            )
        }
    })

    return locations.length > 0 ? (
        <nav className="panel locationPanel">
            <div className="panel-block">
                <div className="control has-icons-right">
                    <input
                        className="input is-medium locationPaneSearch"
                        type="text"
                        placeholder="Find location in list..."
                        value={search}
                        onChange={(changeEvent) =>
                            searchChangeHandler(changeEvent.target.value)
                        }
                    />
                    <span
                        className="icon is-right autoPointerEvents"
                        onClick={() => {
                            setSearch('')
                            setSearchIcon(faSearch)
                        }
                        }
                    >
                        <FontAwesomeIcon icon={searchIcon} />
                    </span>
                </div>
            </div>

            <p className="panel-tabs">
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${sortAlpha
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }`}
                    onClick={() =>
                        setSortAlpha(!sortAlpha)
                    }
                >
                    Sort by name
                </button>
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${!sortAlpha
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }`}
                    onClick={() =>
                        setSortAlpha(!sortAlpha)
                    }
                >
                    Sort by catchment
                </button>
            </p>
            <p className="panel-tabs">
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${typeFilter === 'all'
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }
                    `}
                    onClick={() => setTypeFilter('all')}
                >
                    All
                </button>
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${typeFilter === 'loads'
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }
                    `}
                    onClick={() =>
                        setTypeFilter('loads')
                    }
                >
                    Loads
                </button>
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${typeFilter === 'concs'
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }
                    `}
                    onClick={() =>
                        setTypeFilter('concs')
                    }
                >
                    Concentrations
                </button>
                <button
                    className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${typeFilter === 'rain'
                            ? 'is-active is-primary'
                            : 'is-light has-text-primary'
                        }
                    `}
                    onClick={() =>
                        setTypeFilter('rain')
                    }
                >
                    Rain
                </button>
                <span className="badge_WIP-parent">

                    <button
                        className={`button is-rounded is-size-7-widescreen is-size-7-desktop is-size-6-fullhd
                    ${typeFilter === 'pesticide'
                                ? 'is-active is-primary'
                                : 'is-light has-text-primary'
                            }
                    `}
                        onClick={
                            () => setTypeFilter('pesticide')
                        }
                    >
                        Pesticide
                    </button>
                    <span className="badge_WIP"></span>
                </span>
            </p>
            {typeFilter === 'pesticide' && email &&
                (loading ? <div>Loading...</div> : <div className="card" style={{ margin: '10px' }}>
                    <header className="card-header" style={{ backgroundColor: '#506093' }}>
                        <p className="card-header-title" style={{ color: 'white' }}>
                            Subscribe to pesticide email updates
                        </p>
                        <div className="card-header-icon" aria-label="more options" style={{ top: '-10px', position: 'relative' }}>
                            <input
                                checked={subscribed}
                                onChange={e => { setSubscribed(e.target.checked); updateSubscribed(e.target.checked); }}
                                type="checkbox" style={{ marginRight: '10px' }} className="switch is-rounded is-info" id="switch" ></input><label htmlFor="switch"></label>
                        </div>
                    </header>
                    <div className="card-content">
                        <div className="content">
                            Recieve a monthly email when new pesticide data is available for your favourited locations.
                        </div>
                    </div>
                </div>)
            }
            {filteredLocations().filter((location) =>
                favourites
                    .map((fav) => fav.locationId)
                    .includes(location[0].id)
            ).length > 0 && <p className="panel-heading">Favourites</p>}
            {filteredLocations()
                .filter((location) =>
                    favourites
                        .map((fav) => fav.locationId)
                        .includes(location[0].id)
                )
                .map((location) => renderLocationRow(location))}
            {filteredLocations().some(
                (element) => element[0].locationIdentifier === 'VRG'
            ) && renderListVRG()}
            {sortAlpha
                ? renderAlphabeticalList()
                : renderCatchmentList()}
            {renderNonAlphaLocations()}
        </nav>
    ) : (
        <LoadingPage />
    )
}