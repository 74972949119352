import { months } from "../../Assets/ReuseableStringCons"
import { DataStream, Threshold } from "../../Redux/storeTypes"
import { addGraphColours, toolTipFormatter } from "./DataFunctions/DataFunctions"

function generateSeries(nodes: DataStream[], thresholds: Array<Threshold>): any[] {
    const seriesArray: any[] = [...nodes.map((n: DataStream, i: number) => ({
        type: 'bar',
        name: `${n.name} (${n.unit})`,
        data: n.data,
        hoverAnimation: false,
        xAxisIndex: i,
        yAxisIndex: i,
        itemStyle: {
            color: ['#00507a'],
        },
        barMaxWidth: '25%',
        label: {
            show: false,
            formatter: `{@[1]} \n mm`,
            position: 'bottom',
            fontSize: 15,
            fontWeight: 'bold',
            color: '#00507a',
            distance: 20,
        },
        markLine: {
            symbol: ['none', 'none'],
            data: [
                {
                    name: 'Ecological threshold',
                    yAxis: thresholds.filter(threshold => threshold.compound === n.name && threshold.thresholdType === "Eco")[0]?.threshold ?? -1,
                    lineStyle: {
                        color: ['#B22222'],
                        width: 2
                    }
                },
                {
                    name: 'Detection limit',
                    yAxis: thresholds.filter(threshold => threshold.compound === n.name && threshold.thresholdType === "MinDetectable")[0]?.threshold ?? -1,
                    lineStyle: {
                        color: ['#A9A9A9'],
                        width: 2
                    }
                }
            ],
        }
    })), {
        type: 'line',
        name: 'Detection limit',
        itemStyle: {
            color: ['#A9A9A9']
        }
    }, {
        type: 'line',
        name: 'Ecological threshold',
        itemStyle: {
            color: ['#B22222']
        }
    }]
    return seriesArray
}

export function genPestGraphConfig(nodes: DataStream[], dates: string[], thresholds: Array<Threshold>, maxColumns: number) {
    return {
        tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
                animation: false,
                type: 'none',
            },
            show: true,
            formatter: (args: any[]) => toolTipFormatter(args),
        },
        title: nodes.map((n: DataStream, i: number) => ({
            text: `${n.name} (${n.unit})`,
            textAlign: 'left',
            top: `${(i * 300) + 20}`,
        })),
        dataZoom: [
            {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseWheel: false,
                startValue: dates[dates.length - maxColumns],
                endValue: dates[dates.length - 1],
                xAxisIndex: nodes.map((_n, i: number) => i),
                filterMode: 'weakFilter'
            },
        ],
        color: addGraphColours(nodes),
        grid: nodes.map((_n, i: number) => ({
            left: 50,
            right: 50,
            top: (i * 300) + 50,
            height: 220
        })),
        xAxis: nodes.map((_n: DataStream, i: number) => {
            return {
                type: 'category',
                gridIndex: i,
                position: 'bottom',
                data: dates,
                min: dates[0],
                max: dates[dates.length - 1],
                axisLabel: {
                    formatter: (dateNumber: number) => {
                        const d = new Date(dateNumber)
                        return `${d.getDate()} ${months[d.getMonth()]}\n${d.getFullYear()}`
                    },
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        color: ['rgb(255,255,255)'],
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: ['rgb(255,255,255)'],
                    },
                },
            }
        }),
        yAxis: nodes.map((n: DataStream, i: number) => {
            return {
                type: 'value',
                gridIndex: i,
                nameRotate: 90,
                nameLocation: 'middle',
                splitLine: {
                    show: false,
                },
                scale: 'false',
                min: 0,
                max: Math.round((Math.max(
                    thresholds.filter(threshold => threshold.compound === n.name && threshold.thresholdType === "Eco")[0]?.threshold ?? -1,
                    Math.max(...n.data.map(datapoint => datapoint[1]))
                ) * 1.1 + Number.EPSILON) * 100) / 100,
            }
        }),
        legend: {
            selectedMode: false,
            data: [
                {
                    name: 'Detection limit',
                    icon: 'circle',
                },
                {
                    name: 'Ecological threshold',
                    icon: 'circle',
                },
            ],
        },
        series: generateSeries(nodes, thresholds),
    }
}
