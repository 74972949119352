import CsiroLogo from '../../Assets/csiro-white-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import AsyncButton from '../AsyncButton'
import FetchData from '../../Services/FetchData'
import './Navbar.scss'
import { useAppDispatch, useAppSelect } from '../../Redux/Store'
import { toggleLoginShown, toggleSettingsShown } from '../../Redux/NewReducers'

export default function Navbar() {
    const settingsShown = useAppSelect(state => state.ui.settingsShown)
    const dataShown = useAppSelect(state => state.ui.dataShown)
    const locationsShown = useAppSelect(state => state.ui.locationsShown)
    const mobileLocationsShown = useAppSelect(state => state.ui.mobileLocationsShown)
    const email = useAppSelect(state => state.locations.email)
    const stateIsLoaded = useAppSelect(state => state.stateLoad.stateIsLoaded)
    const dispatch = useAppDispatch()

    return (
        <nav
            className={
                'navbar navbarHeight is-primary' +
                (settingsShown ||
                    dataShown ||
                    locationsShown ||
                    mobileLocationsShown
                    ? ' is-hidden-touch'
                    : '')
            }
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand flexGrow1">
                <span className="flexGrow1 navbar-item justifyLeft">
                    <img className="csiroLogo" src={CsiroLogo} alt="CSIRO Logo"></img>
                </span>
                <div className="container is-size-5 has-text-white has-text-weight-medium centerTitle is-hidden-touch">
                    1622&#8482; Water Quality
                </div>
                <div className="container is-size-1-3 has-text-white has-text-weight-medium centerTitleMobile is-hidden-desktop">
                    1622&#8482; Water Quality
                </div>
                {stateIsLoaded && (
                    <div className="navbar-item">
                        <AsyncButton
                            buttonClassName={'button is-primary'}
                            loadingText="Logging you out..."
                            onClickAsync={
                                email === ''
                                    ? async () =>
                                        dispatch(toggleLoginShown())
                                    : async () =>
                                        await FetchData.Logout()
                            }
                        >
                            <span id="auth-button">
                                <FontAwesomeIcon
                                    icon={
                                        email !== ''
                                            ? faSignOutAlt
                                            : faUser
                                    }
                                />
                                <span className="is-hidden-touch">
                                    &nbsp;&nbsp;{' '}
                                    {!dataShown &&
                                        (email !== ''
                                            ? 'Logout (' +
                                            email.split(
                                                '@'
                                            )[0] +
                                            ')'
                                            : 'Login')}
                                </span>
                            </span>
                        </AsyncButton>

                        {!dataShown && (
                            <div
                                className={
                                    'button is-primary marginLeft10 inlineFlex'
                                }
                                onClick={() => {
                                    dispatch(toggleSettingsShown())
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faSlidersH}
                                    rotation={90}
                                />
                                <span className="is-hidden-touch">
                                    &nbsp;&nbsp; Settings
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </nav>
    )
}