import DataAvailabilityIndicator from '../Services/DataAvailabilityIndicator'
import FetchData from '../Services/FetchData'
import AsyncButton from './AsyncButton'
import { useAppDispatch, useAppSelect } from '../Redux/Store'
import { addFavourite, removeFavourite } from '../Redux/NewReducers'

export default function Favourite(props: { locationId: string }) {
    const favourites = useAppSelect(state => state.favourites.favourites)
    const dispatch = useAppDispatch()
    const email = useAppSelect(state => state.locations.email)

    async function favourite(locationId: string) {
        if (email !== '') {
            await FetchData.updateCloudFav(locationId, false, true)
        }
        const localFavs = [...FetchData.getLocalFavourites(), locationId]
        setLocalFavourites(localFavs)
        dispatch(addFavourite({ locationId: locationId, isLocal: true }))
    }

    async function unfavourite(locationId: string) {
        if (email !== '') {
            await FetchData.updateCloudFav(locationId, true, true)
        }
        const localFavs = FetchData.getLocalFavourites().filter((l: string) => l !== locationId)
        setLocalFavourites(localFavs)
        dispatch(removeFavourite(locationId))
    }

    function setLocalFavourites(favsList: string[]) {
        window.localStorage.setItem(
            FetchData.LOCALSTORAGE_FAVOURITES_KEY,
            JSON.stringify(favsList)
        )
    }

    return (
        <AsyncButton
            buttonClassName={'button is-light ml-1 is-pulled-right'}
            loadingText={'...'}
            onClickAsync={async () => {
                favourites
                    .map((f) => f.locationId)
                    .includes(props.locationId)
                    ? await unfavourite(props.locationId)
                    : await favourite(props.locationId)
            }}
        >
            {favourites
                .map((f) => f.locationId)
                .includes(props.locationId) ? (
                <span>{DataAvailabilityIndicator('favourites')}</span>
            ) : (
                <span>{DataAvailabilityIndicator('hollow')}</span>
            )}
        </AsyncButton>
    )
}
