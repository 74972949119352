import { Fragment, useEffect, useState } from 'react'
import { useAppSelect } from '../../../Redux/Store'
import { DataStream } from '../../../Redux/storeTypes'
import './MissingDataNotification.scss'

export default function MissingDataNotification(props: {
    currentNodes: DataStream[]
}) {
    const [hideTooltip1, setHideTooltip1] = useState(true)
    const [hideToolTip2, setHideToolTip2] = useState(true)
    const [missingMessage1, setMissingMessage1] = useState('')
    const [missingMessage2, setMissingMessage2] = useState('')
    const qualityCodeMapping = useAppSelect(state => state.locations.codeMessageMapping)
    function updateTooltip(toolTipEvent: CustomEvent<Array<[number | string, number, number]>>) {
        const dataToolTip1 = toolTipEvent.detail.find(trio => trio[1] === 0 && trio[2] === 0)
        const missingDataToolTip1 = toolTipEvent.detail.find(trio => trio[1] === 0 && trio[2] !== 0)
        setHideTooltip1(dataToolTip1 !== undefined)
        if (missingDataToolTip1 !== undefined) {
            const qualityCodemMessage = qualityCodeMapping.find(qualityCode => qualityCode.code === missingDataToolTip1[2] && !qualityCode.should_display)?.message ?? 'Sensor has stopped responding'
            setMissingMessage1(qualityCodemMessage)
        }
        if (props.currentNodes.length > 1) {
            const dataToolTip2 = toolTipEvent.detail.find(trio => trio[1] === 1 && trio[2] === 0)
            const missingDataToolTip2 = toolTipEvent.detail.find(trio => trio[1] === 1 && trio[2] !== 0)
            setHideToolTip2(dataToolTip2 !== undefined)
            if (missingDataToolTip2 !== undefined) {
                const qualityCodemMessage = qualityCodeMapping.find(qualityCode => qualityCode.code === missingDataToolTip2[2] && !qualityCode.should_display)?.message ?? 'Sensor has stopped responding'
                setMissingMessage2(qualityCodemMessage)
            }
        }
    }
    useEffect(() => {
        window.addEventListener("toolTip", (toolTipEvent: any) => updateTooltip(toolTipEvent))
        return () => {
            window.removeEventListener("toolTip", (toolTipEvent: any) => updateTooltip(toolTipEvent))
        }
    })
    const containsLoads = useAppSelect(state => state.locations.locations[state.ui.locationId]).nodes.some(node => node.name.includes('loads'))
    return (
        <Fragment>
            <div className={`${containsLoads ? "loadNotification1" : "notification1"} notificationBox ${hideTooltip1 && `is-hidden`}`}>No data: {missingMessage1}</div>
            <div className={`${containsLoads ? "loadNotification2" : "notification2"} notificationBox ${hideToolTip2 && `is-hidden`}`}>No data: {missingMessage2}</div>
        </Fragment>
    )
}