import { Fragment } from 'react'
import FetchData from '../../Services/FetchData'
import SettingsButton from './SettingsButton'
import { useAppDispatch, useAppSelect } from '../../Redux/Store'
import { showAlert, toggleImporterShown, togglesatelliteMap } from '../../Redux/NewReducers'

export default function SettingsPanel() {
    const staff = useAppSelect(state => state.locations.staff)
    const satellite = useAppSelect(state => state.ui.satelliteMap)
    const dispatch = useAppDispatch()

    function toggleSatellite() {
        dispatch(togglesatelliteMap())
        window.localStorage.setItem(
            FetchData.LOCALSTORAGE_SATELLITE_KEY,
            localStorage.getItem(FetchData.LOCALSTORAGE_SATELLITE_KEY)
                ? ''
                : 'y'
        )
        dispatch(
            showAlert({
                text: `Satellite view ${+ satellite ? `disabled` : `enabled`}`,
                is_error: false
            })
        )
    }
    return (
        <div className="pane">
            {staff && (
                <Fragment>
                    <SettingsButton
                        title='Admin panel'
                        text={`This is for data administrators: you can configure
                    groups, stream & location access, user accounts, and more.`}
                        link={process.env.REACT_APP_BACKEND + '/admin'}
                    />
                    <SettingsButton
                        title='New Grab Sample Importer'
                        text='This is the new importer for Farmacist grab sample data.'
                        clickEvent={() => dispatch(toggleImporterShown(true))}
                    />

                    {/*<SettingsButton
                        title={'Location discovery tool'}
                        text={`This option is only shown to staff. This tool
                    crawls Eagle.io for new locations and streams that
                    might not be in the 1622 database.`}
                        clickEvent={() => dispatch(setDiscoveryShown(true))}
                    /> */}
                    <SettingsButton
                        title={'Location and Sensor Importer'}
                        text={`This is for 1622 staff: this tool
                    is used to import sensors in the cross platform format.`}
                        link={
                            process.env.REACT_APP_BACKEND +
                            '/api/thirdPartyImporter'
                        }
                    />
                    <SettingsButton
                        title={'Legacy Grab Sample File Importer'}
                        text={`This is the original and now superceded grab sample importer (see above for the New Grab Sample Importer).`}
                        link={
                            process.env.REACT_APP_BACKEND +
                            '/api/grab_sample_validator'
                        }
                    />
                    {/*<SettingsButton
                        title={'Data filtering report'}
                        text={`This option is only shown to staff. This tool
                    shows quality codes for filtered data streams.`}
                        link={
                            process.env.REACT_APP_BACKEND +
                            '/admin/api/stream/?o=-6.-7.-8.-9'
                        }
                    />*/}
                </Fragment>
            )
            }
            <button
                className="message is-dark"
                onClick={() => toggleSatellite()}
                style={{
                    textSizeAdjust: "100%",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    boxSizing: "inherit",
                    color: "#00598b",
                    cursor: "pointer",
                    borderRadius: 0,
                    fontSize: "1rem",
                    marginBottom: 0,
                    backgroundColor: "#ffffff",
                    border: "none",
                    width: "100%",
                    textAlign: "left",
                    padding: 0,
                }}
            >
                <div className="message-body field">
                    <input
                        type="checkbox"
                        className="is-pulled-right switch"
                        checked={localStorage.getItem(FetchData.LOCALSTORAGE_SATELLITE_KEY) === 'y'}
                        readOnly
                    />
                    <label className="is-pulled-right" />
                    <h3 className="subtitle">Satellite view</h3>
                    <div>Switch between satellite and street styles.</div>
                </div>
                <hr />
            </button>
            <SettingsButton
                title={'Feedback'}
                text={`Send us feedback about 1622™ by emailing 1622@csiro.au`}
                link={`mailto:1622@csiro.au`}
            />
            <SettingsButton
                title={'About'}
                text={`Learn more about 1622™`}
                link={`https://research.csiro.au/digiscape/digiscapes-projects/great-barrier-reef-and-sugarcane-production/`}
            />
        </div >
    )
}